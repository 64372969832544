import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import {
    getConnections, getManualInputList
} from '../../store/apps/connection';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
const CreateManualInputModel = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    // url params 
    const params = useParams();
    const { role } = useStateContext();
    const [isName, setName] = useState('');
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [selectedAssetsAndLiability, setAssetsAndLiability] = useState('Assets');
    const [selectedAssetsAndLiabilityType, setAssetsAndLiabilityType] = useState('');
    const [selectedEntity, setSelectedEntity] = useState('');
    const [balance, setBalance] = useState(0);
    const [isOwned, setOwned] = useState(100);
    const [isAvaibleCash, setAvaibleCash] = useState('no');
    const [isNotes, setNotes] = useState('');
    const [getEntity, setEntity] = useState([]);
    const [isLinkedLiabilites, setLinkedLiabilites] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        if (props_modelHideshow) {
            setLinkedLiabilites(connections.getConnections.liabilities);
            setEntity(connections.getConnections.entity);
        } else {
            resetAllState();
        }
    }, [props_modelHideshow])
    const handleAssetsAndLiabilityChange = (e) => { setSelectedOptions([]); setAvaibleCash('no');; setAssetsAndLiabilityType(''); setAssetsAndLiability(e.target.value); }
    const handleAssetsAndLiabilitypeChange = (e) => {
        if (e.target.value == 'Cash') {
            setAvaibleCash('yes');
        } else {
            setAvaibleCash('no');
        }
        setSelectedOptions([]);
        setAssetsAndLiabilityType(e.target.value);
    };

    const resetAllState = () => {
        setSelectedOptions([]);
        setName('');
        setOwned(100);
        setAvaibleCash('no');
        setNotes('');
        setBalance(0);
        setSelectedEntity('');
        setAssetsAndLiability('Assets');
        setAssetsAndLiabilityType();
    }
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };
    const CreateManualInputFunc = () => {
        const decimalRegex = /^\d+(\.\d{1,2})?$/;
        if (decimalRegex.test(balance)) {
            setEntitySubmit(true)
            if (selectedEntity != '' && selectedAssetsAndLiabilityType != '' && isOwned != '') {
                let postData = {
                    name: isName,
                    typeName: selectedAssetsAndLiabilityType,
                    type: selectedAssetsAndLiability,
                    account: null,
                    entityId: selectedEntity,
                    owned: isOwned,
                    avalibaleCash: isAvaibleCash,
                    value: balance,
                    notes: isNotes,
                    linkedLiabilites: selectedOptions,
                    connectionType: 'manual-input'
                }
                let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.post('manual-input/save' + queryParams, postData)
                    .then(({ data }) => {
                        resetAllState();
                        setEntitySubmit(false);
                        toast.success(data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                        props_callModelCloseFunction();
                    }).catch(error => {
                        console.log('error occurd', error);
                        toast.success('There is some error while adding entity.Try again.', {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                        setEntitySubmit(false);
                    })
            } else {
                setEntitySubmit(false);
            }
        } else {
            toast.error('Value is invalid. Please add a number up to two decimal places.', {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
            });
        }


    }

    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Manual Input</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="entity_type" className="form-label">
                                Asset or Liability:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedAssetsAndLiability}
                                onChange={handleAssetsAndLiabilityChange}
                                required
                            >
                                <option value="Assets">Asset</option>
                                <option value="Liability">Liability</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="entity_type" className="form-label">
                                {selectedAssetsAndLiability} Type:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedAssetsAndLiabilityType}
                                onChange={handleAssetsAndLiabilitypeChange}
                                required
                            >
                                <option value="">Select Type</option>
                                {
                                    selectedAssetsAndLiability == 'Assets'
                                        ?
                                        <>
                                            <option value="Cash">Cash</option>
                                            <option value="Non-Registered Investments">Non-Registered Investments</option>
                                            <option value="Registered Investments">Registered Investments</option>
                                            <option value="Real Estate">Real Estate</option>
                                            <option value="Other Assets">Other Assets</option>
                                        </>
                                        :
                                        <>
                                            <option value="Mortgages">Mortgages</option>
                                            <option value="Loans/Leases">Loans/Leases</option>
                                            <option value="Line of Credit">Line of Credit</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Other Liability">Other Liability</option>
                                        </>
                                }
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="link_to_entity" className="form-label">
                                Link to Entity:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedEntity}
                                onChange={(e) => { setSelectedEntity(e.target.value) }}
                                required
                            >
                                <option value="">Select Entity</option>
                                {getEntity.map((entity, index) => (
                                    <option value={entity.id}>{entity.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3 percent-input-container">
                            <label htmlFor="owned" className="form-label">
                                %Owned:
                            </label>
                            <input
                                type="text"
                                className={isOwned > 0 ? 'form-control positive-balance' : 'form-control'}
                                value={isOwned}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Remove non-numeric characters except the dot (.)
                                    const numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, ''));
                                    // Ensure numericValue is not NaN (e.g., if the input is empty or invalid)
                                    if (!isNaN(numericValue)) {
                                        if (numericValue > 100) {
                                            setOwned(100);
                                        } else {
                                            setOwned(numericValue);
                                        }
                                    } else {
                                        setOwned('');
                                    }
                                }}
                                id="owned"
                                required
                                max="100"
                            />
                            <span className="percent-symbol mt-3">%</span>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Account" className="form-label">
                                Add to Available Cash:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={isAvaibleCash}
                                onChange={e => setAvaibleCash(e.target.value)}
                                required
                            >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>

                        <div className="mb-3 dollar-input-container">
                            <label htmlFor="balance" className="form-label">
                                Value:
                            </label>
                            <input
                                type="text"
                                className={parseFloat(balance) > 0 ? 'form-control positive-balance' : 'form-control'}
                                id="balance"
                                value={balance}
                                onChange={(e) => setBalance(e.target.value)}
                            />
                            <span className="dollar-sign mt-3">$</span>
                        </div>
                        {
                            selectedAssetsAndLiabilityType == 'Real Estate'
                                ?
                                <div className="mb-3">
                                    <label htmlFor="Account" className="form-label">
                                        Linked Liabilities:
                                    </label>

                                    <Select options={isLinkedLiabilites} isMulti value={selectedOptions}
                                        onChange={handleSelectChange} />
                                </div>
                                :
                                null
                        }
                        <div className="mb-3">
                            <label htmlFor="notes" className="form-label">
                                Notes:
                            </label>
                            <textarea
                                id="notes"
                                className="form-control"
                                required
                                value={isNotes} onChange={e => setNotes(e.target.value)}
                            ></textarea>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={isName.length > 0 ? false : true}
                        onClick={CreateManualInputFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Save</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateManualInputModel;