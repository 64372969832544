import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import axios from 'axios';
import Toggle from 'react-toggle'
let count = 0;
const CreateAdvisorModel = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    const [isName, setName] = useState('');
    const [isUserEmail, setUserEmail] = useState(null);
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [meetingUrl, setMeetingUrl] = useState('');
    const [designation, setDesignation] = useState('');
    const [logo, setLogo] = useState(null);
    const [urlError, setUrlError] = useState('');
    const [isS3Url, setS3Url] = useState(null);
    const [isSetPassswordFields, setPasswordFields] = useState(false);
    const [isSendInvitation, setSendInvitation] = useState(true);
    const [isPassword, setPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [selectedOption, setSelectedOption] = useState('sendInvitation');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    useEffect(() => {
        if (props_modelHideshow) {
        } else {
            setName('');
            setUserEmail(null);
            setIsValidEmail(true);
            setAddress('');
            setPhone('');
            setMeetingUrl('');
            setDesignation('');
            setLogo(null);
            setUrlError('');
            setS3Url('');
            setPasswordFields(false);
            setSendInvitation(true);
            setPassword(null);
            setConfirmPassword(null);
        }
    }, [props_modelHideshow])
    const createAdvisorFunc = () => {
        if (isSetPassswordFields) {
            if (isPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
                return; // Don't submit if passwords don't match or have errors
            }
        }
        setEntitySubmit(true)
        let postData = {
            name: isName,
            email: isUserEmail,
            address: address,
            phone: phone,
            url: meetingUrl,
            designation: designation,
            logo: isS3Url,
            password: isPassword,
            sendInvitation: selectedOption == 'sendInvitation' ? true : false
        }
        axiosClient.post('advisor-user/create', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    if (logo != null) {
                        let logoObj = new FormData();
                        logoObj.append('fileName', isS3Url);
                        axiosClient.post('advisor-user/s3-url', logoObj)
                            .then(({ data }) => {
                                axios.put(data.pre_url, logo).then(
                                    result => {
                                        if (result && result.status === 200) {
                                        }
                                    }
                                )
                            }).catch(error => {
                            })
                    }
                    setEntitySubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setEntitySubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }
    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };

    const handleLogoChange = (e) => {
        // Handle logo file upload
        const file = e.target.files[0];
        const fileName = file['name'].slice(((file['name'].lastIndexOf(".") - 1) >>> 0) + 2);
        const s3FileName = generateRandomString(100) + '.' + fileName;
        setS3Url(s3FileName);
        setLogo(file);
    };
    const validateUrl = (url) => {
        try {
            new URL(url);
            return '';
        } catch (error) {
            return "Please enter a valid URL.";
        }
    }
    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setMeetingUrl(newUrl);
        const error = validateUrl(newUrl);
        setUrlError(error);
    };
    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };
    const handlePasswordChange = (event) => {
        // console.log('evenmt',event.target.value);
        setPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Advisor</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        {/* Advisor Name */}
                        <div className="mb-3">
                            {/* field Name label*/}
                            <label htmlFor="name" className="form-label">
                                Name*:
                            </label>
                            {/* Advisor Name input */}
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                                Email*:
                            </label>
                            <input
                                type="email"
                                value={isUserEmail}
                                onChange={handleEmailChange}
                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                id="exampleInputPassword1"
                            />
                            {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                        </div>  <div className="mb-3">
                            <label htmlFor="address" className="form-label">
                                Address:
                            </label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="form-control"
                                id="address"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">
                                Phone:
                            </label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => {
                                    const x = e.target.value
                                        .replace(/\D/g, '')
                                        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                    let number = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
                                    setPhone(number)
                                }}
                                className="form-control"
                                id="phone"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="meetingUrl" className="form-label">
                                Meeting URL (e.g. Calendly):
                            </label>
                            <input
                                type="url"
                                value={meetingUrl}
                                onChange={handleUrlChange}
                                className={`form-control ${urlError == '' ? '' : 'is-invalid'}`}
                                id="meetingUrl"
                            />
                        </div>
                        {urlError && <div className="error-message">{urlError}</div>}
                        <div className="mb-3">
                            <label htmlFor="designation" className="form-label">
                                Designation:
                            </label>
                            <input
                                type="text"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                className="form-control"
                                id="designation"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="logo" className="form-label">
                                Logo:
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoChange}
                                className="form-control"
                                id="logo"
                            />
                        </div>
                        {/* Conditionally render the image */}
                        {logo && (
                            <div>
                                <p>Selected Logo:</p>
                                <img src={URL.createObjectURL(logo)} height={100} width={100} alt="Selected Logo" />
                            </div>
                        )}
                        {/* <div className="mb-3 d-flex" key={count}>
                            <label className="d-flex">
                                <Toggle
                                    defaultChecked={isSetPassswordFields}
                                    icons={false}
                                    onChange={(e) => { setPasswordFields(!isSetPassswordFields); setSendInvitation(!isSendInvitation); count = count + 1 }} />
                                <span className="pl-2">Set Password</span>
                            </label>
                            <label className="d-flex">
                                <Toggle
                                    defaultChecked={isSendInvitation}
                                    icons={false}
                                    onChange={() => { setPasswordFields(!isSetPassswordFields); setSendInvitation(!isSendInvitation); count = count + 1 }} />
                                <span className="pl-2">Send Invitation</span>
                            </label>
                        </div> */}
                        <div className="mb-3 d-flex">
                            <label className="d-flex">
                                <input
                                    type="radio"
                                    value="setPassword"
                                    checked={selectedOption === 'setPassword'}
                                    onChange={handleOptionChange}
                                />
                                <span className="pl-2">Set Password</span>
                            </label>
                            <label className="d-flex" style={{marginLeft:'10px'}}>
                                <input
                                    type="radio"
                                    value="sendInvitation"
                                    checked={selectedOption === 'sendInvitation'}
                                    onChange={handleOptionChange}
                                />
                                <span className="pl-2">Send Invitation</span>
                            </label>
                        </div>
                        {
                          selectedOption === 'setPassword'
                                ?
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">New Password</label>
                                        <input type="password"
                                            className="form-control"
                                            id="password"
                                            value={isPassword}
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                        {passwordError && <div className="error">{passwordError}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <input type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            onChange={(e) => handleConfirmPasswordChange(e)}
                                        />
                                        {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                    </div>
                                </>
                                :
                                null
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={isValidEmail && isName.length > 0 ? false : true}
                        onClick={createAdvisorFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Save</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateAdvisorModel;