import React, { useEffect, useState } from "react";
import { ComponentLoading } from "../Components/ComponentLoading"
import zoom from '../images/zoom.png';
import DashboardNetWorthModel from "../Components/Modals/DashbboardNetWorthModal";
import { useDispatch, useSelector } from 'react-redux'
import {
  getDashboardData
} from '../store/apps/connection'
import LineChart from '../Components/Chart/LineChart';
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
const Dashboard = () => {
  // url params 
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { permissions, role } = useStateContext();
  const [isDashboardData, setDashboardData] = useState(null);
  const [isReportEntityModalType, setReportEntityModalType] = useState('');
  const [isLoading, setIsLoading] = useState(true)
  const [isHideShowNetWorthModel, setHideShowNetWorthModel] = useState(false);
  const [isAdvisorClientView, setAdvisorClientView] = useState(null);
  const connections = useSelector((state) => state.connection)
  const handleHideShowNetWorthModel = () => {
    setReportEntityModalType('');
    setHideShowNetWorthModel(false)
  };
  const callDashboardNetworthModel = (param) => {
    if (param == 'personal') {
      setReportEntityModalType(isDashboardData.personalEntity);
      setHideShowNetWorthModel(true);
    } else {
      setReportEntityModalType(isDashboardData.businessEntity)
      setHideShowNetWorthModel(true)
    }

  };
  useEffect(() => {
    setIsLoading(true)
  }, [dispatch])
  useEffect(() => {
    if (permissions != undefined) {
      if (_.includes(permissions, 'view_dashboard')) {
        if (params && params.userId && role == 'advisor') {
          dispatch(getDashboardData(params.userId));
        } else if (role == 'advisor') {
          navigate('/page-404');
        } else if (role != null) {
          dispatch(getDashboardData());
        }
      } else {
        navigate('/page-404');
      }
    }
  }, [permissions])
  useEffect(() => {
    if (connections.getDashboardData && connections.getDashboardData.data) {
      setDashboardData(connections.getDashboardData.data);
    } else {
      if (connections.getDashboardData.status) {
        navigate('/page-404');
      }
    }
  }, [connections.getDashboardData])
  const formatBalance = (value) => {
    const formattedValue = Number(value).toLocaleString('en-US');
    return `$${formattedValue}`;

  };
  return (
    <>
      {isLoading && isDashboardData != null ?
        <>
          <div className="main">
            <div className="heading">
              <p>
                {isDashboardData.userName} dashboard
              </p>
            </div>
            <div className="net_worth d-flex">
              <div className="d-flex worth_1">
                <div className="bars">
                  <div className="bar-1">
                  </div>
                  <div className="bar-2">
                  </div>
                </div>
                <div className="net_worth_information">
                  <div>
                    <h6>
                      personal net worth:
                    </h6>
                    <h6>
                      {formatBalance(isDashboardData.personal.total)}
                    </h6>
                    <div className="d-flex subAmount mt-3">
                      <div className="bullet">
                      </div>
                      <p>
                        {formatBalance(isDashboardData.personal.assets)}
                      </p>
                    </div>
                    <div className="d-flex subAmount subAmount_2">
                      <div className="bullet">
                      </div>
                      <p>
                        {formatBalance(isDashboardData.personal.liability)}
                      </p>
                    </div>
                  </div>

                </div>
                <div className="ms-auto m-2 me-4">
                  <a href="#" onClick={() => callDashboardNetworthModel('personal')}>
                    <img src={zoom} alt="img" />
                  </a>
                </div>
              </div>
              <div className="d-flex worth_1">
                <div className="bars">
                  <div className="bar-3">
                  </div>
                  <div className="bar-4">
                  </div>
                </div>
                <div className="net_worth_information net_worth_information_2">
                  <div>
                    <h6>
                      business net worth:
                    </h6>
                    <h6>
                      {formatBalance(isDashboardData.business.total)}
                    </h6>
                    <div className="d-flex  subAmount_business mt-3">
                      <div className="bullet">
                      </div>
                      <p>
                        {formatBalance(isDashboardData.business.assets)}
                      </p>
                    </div>
                    <div className="d-flex subAmount_business  subAmount_business_2">
                      <div className="bullet">
                      </div>
                      <p>
                        {formatBalance(isDashboardData.business.liability)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="ms-auto m-2">
                  <a href="#" onClick={() => callDashboardNetworthModel('business')}>
                    <img src={zoom} alt="img" />
                  </a>
                </div>
              </div>
            </div>

            <div className="chart">
              <div className="trends_chart">
                <div className="mx-4 my-4 d-flex justify-content-between">
                  <div className="trand_heading">
                    <h1>
                      Trends
                    </h1>
                  </div>
                  <div className="trand_heading d-flex mt-1">

                    <input type="radio" className="btn-check" name="options" id="option1" autoComplete="off"
                    />
                    <label className="btn btn-primary custom_dashboard_btn_trends ms-2" htmlFor="option1">last 30 days</label>
                    {/* 
                    <input type="radio" className="btn-check" name="options" id="option2" autoComplete="off" />
                    <label className="btn btn-primary ms-2" for="option2">Week</label>

                    <input type="radio" className="btn-check" name="options" id="option3" autoComplete="off" />
                    <label className="btn btn-primary ms-2" for="option3">Month</label>

                    <input type="radio" className="btn-check" name="options" id="option4" autoComplete="off" />
                    <label className="btn btn-primary ms-2" for="option4">Year</label>
                    <div>
                      <i className="fa fa-calendar-days"></i>
                    </div> */}
                  </div>
                </div>
                <div className="mx-4 my-3">
                  <LineChart />
                </div>
              </div>
            </div>
          </div> </>
        :

        <div className="card loadingCard">
          <div className="card-body">
            <ComponentLoading></ComponentLoading>
          </div>
        </div>}

      <DashboardNetWorthModel props_modelHideshow={isHideShowNetWorthModel} props_callModelCloseFunction={handleHideShowNetWorthModel}
        props_PersonalAndBusniessEntity={isReportEntityModalType}
      />
    </>


  )
}

export default Dashboard