// ** Toolkit imports
import { configureStore } from '@reduxjs/toolkit';

// ** Reducers
import connection from './apps/connection';

export const store = configureStore({
  reducer: {
    connection,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
