import { Outlet, Navigate, NavLink, Link } from 'react-router-dom';
import axiosClient from "../axios-client.js";
import { useStateContext } from "../Context/ContextProvider";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar.jsx';
import '../CSS/styles.css';
import { Modal } from "react-bootstrap";

export default function DefaultLayout() {

    const navigate = useNavigate();

    const { token, setUser, setRole, setPermissions } = useStateContext();
    const [showLocationModal, setLocationModal] = useState(false);
    const handleLocationModalClose = () => setLocationModal(false);
    const [btnLoading, setBtnLoading] = useState(false)
    const [address, setAddress] = useState("");
    const [getIsLoading, setIsLoading] = useState(true)
    const [getUserRole, setUserRole] = useState("");
    const getUser = () => {
        axiosClient.get('/user')
            .then(({ data }) => {
                if (data.message == "Unauthenticated.") {
                    navigate('/login')
                } else {
                    setPermissions(data.permissions);
                    setUser(data.user)
                    setRole(data.role)
                    setAddress(data.user.address)
                    setUserRole(data.role)
                    setIsLoading(false)
                    return data
                }
            })
            .catch(err => {
                navigate('/login')
                setIsLoading(false)
            });
    }

    useEffect(() => {

        document.body.classList.remove('bg-auth');
        document.body.classList.add('bg-app');
        document.body.classList.add('layout-menu-fixed');
        getUser();
        window.addEventListener('refreshProfile', (event) => {
            if (event['detail'].value) {
                getUser();
            }
        })

    }, [getUserRole, getIsLoading])

    if (!token) {
        return <Navigate to="/login" />
    }


    return (
        <>
            <Sidebar getUserRole={getUserRole} getIsLoading={getIsLoading} />
            <div className="layout-page">

                <div className="content-wrapper">
                    <Outlet />
                </div>
            </div>

            <Modal
                backdrop="static"
                centered
                show={showLocationModal}
                onHide={handleLocationModalClose}
                className="modal-md"
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="btnClose"
                        onClick={handleLocationModalClose}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                    <form >
                        <div className="planA">
                            <h1 className="name text-center">!</h1>
                            <div className="shortDescription text-center">
                                <p></p>
                            </div>
                            <div className="text-center">
                            </div>
                            <div className='text-center mt-3'>
                                <button type="submit" className="btn btn-primary btn-block" disabled={btnLoading || address == ''}>
                                    {
                                        btnLoading ?
                                            (
                                                <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                            ) : (
                                                <>Save Location</>
                                            )
                                    }
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

        </>
    )
}