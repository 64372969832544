import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import zoom from '../../images/zoom_out.png';
import ChartComponent from "../Chart/ChartComponent";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
let doughnutChartData = {
    labels: [],
    datasets: [
        {
            data: [],
            backgroundColor: [],
            hoverBackgroundColor: [],
        },
    ],
};
let chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: false, // Set to true if you want to display the legend
        },
        tooltip: {
            callbacks: {
                label: (context) => {
                    const dataIndex = context.dataIndex;
                    const dataset = context.dataset;
                    const data = dataset.data[dataIndex];
                    const total = dataset.data.reduce((acc, val) => acc + val, 0);
                    const percentage = ((data / total) * 100).toFixed(2);
                    return ` ${data}%`;
                }
            }
        }
    }
}
const DashboardNetWorthModel = ({ props_modelHideshow, props_callModelCloseFunction, props_PersonalAndBusniessEntity }) => {
    // url params 
    const params = useParams();
    const {  role } = useStateContext();
    const [isEntity, setEntity] = useState([]);
    const [isReportData, setReportData] = useState({});
    const [selectedEntity, setSelectedEntity] = useState('');
    const [selectedAssets, setSelectedAssets] = useState(true);
    const [selectedLiability, setSelectedLiability] = useState(true);
    const [isColors, setColors] = useState([]);
    useEffect(() => {
        if (props_PersonalAndBusniessEntity.length > 0) {
            getAssetAnInvestmentAgaistEntity(props_PersonalAndBusniessEntity)
            setEntity(props_PersonalAndBusniessEntity)
        } else {
            setEntity([]);
            setSelectedEntity('');
            setSelectedAssets(true);
            setSelectedLiability(true);
            setColors([]);
            doughnutChartData = {
                labels: [],
                datasets: [
                    {
                        data: [],
                        backgroundColor: [],
                        hoverBackgroundColor: [],
                    },
                ],
            }
            chartOptions = {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false, // Set to true if you want to display the legend
                    },
                    tooltip: {
                        callbacks: {
                            label: (context) => {
                                const dataIndex = context.dataIndex;
                                const dataset = context.dataset;
                                const data = dataset.data[dataIndex];
                                const total = dataset.data.reduce((acc, val) => acc + val, 0);
                                const percentage = ((data / total) * 100).toFixed(2);
                                return ` ${data}%`;
                            }
                        }
                    }
                }
            }
        }
    }, [props_PersonalAndBusniessEntity])

    const getAssetAnInvestmentAgaistEntity = (param) => {
        if (param.length > 0) {
            setSelectedEntity(param[0].id);
            let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
            axiosClient.post('dashboard/asset-libality/report' + queryParams, { id: param[0].id, assetStatus: true, liabilityStatus: true })
                .then(async ({ data }) => {
                    if (data.status == 200) {
                        setReportData(data.data);
                        let dataArray = await data.data.linksData;
                        const typeNames = await dataArray.map((item) => item.typeName);
                        // Extract 'owned' separately
                        const ownedValues = await dataArray.map((item) => item.owned);
                        const colorbg = await dataArray.map((item) => item.colorType.color);
                        setColors(colorbg);
                        doughnutChartData.labels = typeNames;
                        doughnutChartData.datasets[0].data = ownedValues;
                        doughnutChartData.datasets[0].backgroundColor = colorbg;
                        doughnutChartData.datasets[0].hoverBackgroundColor = colorbg;
                    }
                }).catch(error => {
                    console.log('error occurd dashboard asset libality')
                })
        }
    }
    const selectAssetAnInvestmentAgaistEntity = (param, assetStatus, liabilityStatus) => {
        setSelectedAssets(assetStatus);
        setSelectedLiability(liabilityStatus);
        doughnutChartData.labels = [];
        let queryParams = params && params.userId &&  role == 'advisor'? '?userId=' + params.userId : '';
        axiosClient.post('dashboard/asset-libality/report' + queryParams, { id: param, assetStatus: assetStatus, liabilityStatus: liabilityStatus })
            .then(async ({ data }) => {
                if (data.status == 200) {
                    setReportData(data.data);
                    let dataArray = await data.data.linksData;
                    const typeNames = await dataArray.map((item) => item.typeName);
                    // Extract 'owned' separately
                    const ownedValues = await dataArray.map((item) => item.owned);
                    const colorbg = await dataArray.map((item) => item.colorType.color);
                    setColors(colorbg);
                    doughnutChartData.labels = typeNames;
                    doughnutChartData.datasets[0].data = ownedValues;
                    doughnutChartData.datasets[0].backgroundColor = colorbg;
                    doughnutChartData.datasets[0].hoverBackgroundColor = colorbg;
                }
            }).catch(error => {
                console.log('error occurd dashboard asset libality')
            })
    }
    const formatBalance = (value) => {
        const formattedValue = Number(value).toLocaleString('en-US');
        return `$${formattedValue}`;
    };

    return (
        <>
            {props_modelHideshow
                ?
                <div className="flow_chart">
                    <div className="full_chart">
                        <div className="m-2 d-flex justify-content-end" style={{ marginTop: '-1rem !important' }}>
                            <a href="#" onClick={props_callModelCloseFunction}>
                                <img src={zoom} alt="img" />
                            </a>
                        </div>
                        <div className="chart_widget d-flex">
                            <div className="wediget">
                                <p>
                                    current value
                                </p>
                            </div>
                            <div className="wediget2">
                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input me-3" onChange={event => selectAssetAnInvestmentAgaistEntity(selectedEntity, !selectedAssets, selectedLiability)}
                                        value={selectedAssets}
                                        checked={selectedAssets}
                                        type="checkbox" id="flexCheckDefaultAsset" />
                                    <p className="mt-1">
                                        Assets: {isReportData.totalAssets ? formatBalance(isReportData.totalAssets) : '$0'}
                                    </p>
                                </div>
                            </div>
                            <div className="wediget2 wediget3 ">
                                <div className="form-check d-flex align-items-center">
                                    <input className="form-check-input me-3" onChange={event => selectAssetAnInvestmentAgaistEntity(selectedEntity, selectedAssets, !selectedLiability)} type="checkbox" value={selectedLiability} checked={selectedLiability} id="flexCheckDefaultLiability" />
                                    <p className="mt-1">
                                        Liabilities: {isReportData.totalLiability ? formatBalance(isReportData.totalLiability) : '$0'}
                                    </p>
                                </div>
                            </div>
                            <div className="wediget4">
                                <p className="mt-1">
                                    {isReportData.totalremaning ? formatBalance(isReportData.totalremaning) : '$0'}
                                </p>
                            </div>
                        </div>
                        <div className="chart_silder d-flex">
                            <div className="chart_one">
                                <div className="mortage liability">
                                    <div className="accordion" id="accordionPanelsStayOpenExample">
                                        {
                                            isReportData && isReportData.linksData && isReportData.linksData.length > 0
                                                ?
                                                <>
                                                    {isReportData.linksData.map((link, index) => (
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                                                <button className="accordion-button collapsed" type="button"
                                                                    data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapseOne${index}`}
                                                                    aria-expanded="true" aria-controls={`panelsStayOpen-collapseOne${index}`}>
                                                                    <div className="mortage_div " >
                                                                        <p className="color" style={{ backgroundColor: isColors[index] }}>

                                                                        </p>
                                                                        <p style={{ color: isColors[index] }}>
                                                                            {link.owned + '%'}
                                                                        </p>
                                                                        <p style={{ color: isColors[index] }}>
                                                                            {link.typeName}({link.balance})
                                                                        </p>
                                                                    </div>
                                                                    <div className="ms-auto">
                                                                        <p className="text_chart">
                                                                            {link.type}
                                                                        </p>
                                                                    </div>

                                                                </button>
                                                            </h2>
                                                            <div id={`panelsStayOpen-collapseOne${index}`} className="accordion-collapse collapse "
                                                                aria-labelledby="panelsStayOpen-headingOne">
                                                                <div className="accordion-body">
                                                                    <table className="table table2">
                                                                        <thead>
                                                                            <tr>
                                                                                <th scope="col" className="text-center">Account No.</th>
                                                                                <th scope="col" className="text-center">Name</th>
                                                                                <th scope="col" className="text-center">Balance</th>
                                                                                <th scope="col" className="text-center">Type</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {
                                                                                link.accounts != null && link.accounts.length > 0

                                                                                    ?
                                                                                    <>
                                                                                        {link.accounts.map((account, ind) => (

                                                                                            <tr key={ind}>
                                                                                                <td className="text-center">

                                                                                                    {
                                                                                                        account.connectionType == 'banking' ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    account && account.accountNo
                                                                                                                        ?
                                                                                                                        account.accountNo
                                                                                                                        :
                                                                                                                        'N/A'
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    account.connectionType == 'investment' ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.AccountId
                                                                                                                                    ?
                                                                                                                                    account.AccountId
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.accountId
                                                                                                                                    ?
                                                                                                                                    account.accountId
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>
                                                                                                    }

                                                                                                </td>
                                                                                                <td className="text-center">
                                                                                                    {
                                                                                                        account.connectionType == 'banking' ?
                                                                                                            <>
                                                                                                                {
                                                                                                                    account && account.name
                                                                                                                        ?
                                                                                                                        account.name
                                                                                                                        :
                                                                                                                        'N/A'
                                                                                                                }
                                                                                                            </>
                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    account.connectionType == 'investment' ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.Name
                                                                                                                                    ?
                                                                                                                                    account.Name
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.Name
                                                                                                                                    ?
                                                                                                                                    account.Name
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>
                                                                                                    }


                                                                                                </td>
                                                                                                <td className="text-center">
                                                                                                    {
                                                                                                        account.connectionType == 'banking' ?
                                                                                                            <>
                                                                                                                {account.availableBalance != null
                                                                                                                    ?
                                                                                                                    account.availableBalance
                                                                                                                    :
                                                                                                                    'N/A'
                                                                                                                }
                                                                                                            </>
                                                                                                            :

                                                                                                            <>
                                                                                                                {
                                                                                                                    account.connectionType == 'investment' ?
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.AccountValue
                                                                                                                                    ?
                                                                                                                                    account.AccountValue
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                account && account.value
                                                                                                                                    ?
                                                                                                                                    account.value
                                                                                                                                    :
                                                                                                                                    'N/A'
                                                                                                                            }
                                                                                                                        </>
                                                                                                                }
                                                                                                            </>


                                                                                                    }
                                                                                                </td >
                                                                                                <td className="text-center">{
                                                                                                    account.accountRegOrNot
                                                                                                        ?
                                                                                                        account.accountRegOrNot
                                                                                                        :
                                                                                                        'N/A'
                                                                                                }</td>

                                                                                            </tr>
                                                                                        ))}
                                                                                    </>
                                                                                    :
                                                                                    null
                                                                            }
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    ))}</>
                                                :
                                                null
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="chart_two">
                                <div className="backdown">
                                    <div className="accordion accordion2" id="accordionExample">
                                        <div className="accordion-header" id="headingOne">
                                            <select
                                                className="form-select"
                                                id="type"
                                                value={selectedEntity}
                                                onChange={(e) => {
                                                    if (e.target.value != '') {
                                                        selectAssetAnInvestmentAgaistEntity(e.target.value, true, true)
                                                        setSelectedEntity(e.target.value);
                                                    } else {
                                                        setSelectedEntity(e.target.value);
                                                    }
                                                }}
                                                required
                                                style={{ width: '95%' }}
                                            >
                                                <option value="">Select Entity</option>
                                                {isEntity.map((entity, index) => (
                                                    <option value={entity.id}>{entity.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="chart_img">{
                                        doughnutChartData.labels.length > 0
                                            ?
                                            <ChartComponent type="doughnut" data={doughnutChartData} options={chartOptions} />
                                            :
                                            null
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default DashboardNetWorthModel;