import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import connection, {
    getUsersData,
    clientDataForDashboard
} from '../store/apps/connection'
import { useNavigate } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
import CreateClientUserModel from "../Components/Modals/CreateClientUser";
import { CornerDownLeft } from "react-feather";
const Clients = () => {
    // redux dispatch fucntion
    const dispatch = useDispatch();
    // navigation function
    const navigate = useNavigate();
    // get permission from context provider
    const { permissions, role } = useStateContext();
    // redux fetch data state
    const connections = useSelector((state) => state.connection)
    // table loading state
    const [isLoading, setIsLoading] = useState(false);
    // client table data state
    const [isClientsData, setClientsData] = useState(null);
    //model state 
    const [isShowCreateClientModel, setShowCreateClientModel] = useState(false);
    useEffect(() => {
        setIsLoading(true)
        dispatch(getUsersData());
    }, [dispatch])
    // useEffect get user/admins api response in this..
    useEffect(() => {
        if (connections.getUsersData && connections.getUsersData.status == 200) {
            setClientsData(connections.getUsersData.clients)
        }
    }, [connections.getUsersData])
    useEffect(() => {
        if (permissions != undefined) {
            if (role != undefined && role != 'admin' && _.includes(permissions, 'view_clients')) {
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    const addClientUser = () => {
        setShowCreateClientModel(true);
    }
    const handleShowCreateClientModalClose = () => {
        setShowCreateClientModel(false);
        dispatch(getUsersData());
    }
    const selectClientUser = (id) => {
        // dispatch(clientDataForDashboard(id));
        // setTimeout(() => {
            navigate('/dashboard/' + id);
        // }, 500);
    }


    return (
        <>
            <div class="main_client">
                {
                    permissions != undefined && _.includes(permissions, 'manage_clients')
                        ?
                        <div class="main_client_btn" onClick={() => addClientUser()}>
                            <button>
                                Create Client
                            </button>
                        </div>
                        :
                        null
                }

                <div class="main_client_header">
                    <div>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" placeholder="Search name" id="form-control" />
                            <span class="input-group-text" id="basic-addon1"><i class="fa fa-search"></i></span>

                        </div>
                    </div>
                    <div>
                        {/* <input type="date" class="form-control form-input" /> */}
                    </div>
                    <div class="d-flex align-items-center">
                        {/* <span class="me-2" >to</span> <input type="date"
                            class="form-control form-input" /> */}
                    </div>
                    <div>
                        {/* <select class="form-select form-input form-select" aria-label="Default select example">
                            <option value="1">Sort</option>
                            <option value="2">Two</option>

                        </select> */}
                    </div>
                    <div>
                        {/* <div class="form-check">
                            <input class="form-check-input form-radio" type="checkbox" name="flexRadioDefault"
                                id="flexRadioDefault1" />
                            <label class="form-check-label" for="flexRadioDefault1">
                                Show inactive
                            </label>
                        </div> */}
                    </div>
                </div>
                <div class="main_client_main">
                    {
                        isClientsData != null && isClientsData.length > 0
                            ?
                            <>
                                {isClientsData.map((user, index) => (
                                    <div class="client_main_wrapper">
                                        <div class="client_wrapper_text">
                                            <h1>
                                                {user.name}
                                            </h1>
                                            <p>
                                                {user.email}
                                            </p>

                                        </div>
                                        <div class="client_wrapper_amount amount_1">
                                            <h1>
                                                total net worth
                                            </h1>
                                            <p>
                                                $1,201,0080.0
                                            </p>
                                        </div>
                                        <div class="client_wrapper_amount">
                                            <h1>
                                                change net worth
                                            </h1>
                                            <p>
                                                $1,201,0080.0 <span>16% <i class="fa fa-arrow-trend-up"></i></span>
                                            </p>
                                        </div>
                                        <div class="client_wrapper_btn client_wrapper_btn_1">
                                            <button onClick={() => selectClientUser(user.id)}>
                                                view dashboard
                                            </button>
                                        </div>
                                        <div>
                                            <i class="fa-solid fa-ellipsis-vertical fs-5" ></i>
                                        </div>
                                    </div>

                                ))}
                            </>
                            :
                            null

                    }
                    <div class="main_client_footer">
                        <p>
                            ({isClientsData != null && isClientsData.length > 0 ? isClientsData.length : 0}) total client net worth: <span>$57,648,384.00</span>
                        </p>
                        {/* <div class="ms-auto mt-3">
                            <nav aria-label="Page navigation example ">
                                <ul class="pagination">
                                    <li class="page-item link-frist"><a class="page-link  " href="#"><i
                                                class="fa-solid fa-caret-left"></i></a></li>
                                    <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">....</a></li>
                                    <li class="page-item"><a class="page-link" href="#">7</a></li>
                                    <li class="page-item"><a class="page-link" href="#">8</a></li>
                                    <li class="page-item link-frist"><a class="page-link active" href="#"><i
                                                class="fa-solid fa-caret-right "></i></a></li>
                                </ul>
                            </nav>
                        </div> */}
                    </div>
                </div>
                <div class="client_footer">
                    {/* <select class="form-select  form-select-2" aria-label="Default select example">
                        <option value="1">Client Per Page 7:</option>
                        <option value="2">Two</option>

                    </select> */}
                </div>
            </div>
            <CreateClientUserModel props_modelHideshow={isShowCreateClientModel} props_callModelCloseFunction={handleShowCreateClientModalClose} />
        </>
    )
}

export default Clients;