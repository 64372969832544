import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import connection, {
    removeConnectionDetails
} from '../../store/apps/connection';
import axiosClient from "../../axios-client";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../../Context/ContextProvider";

const PlaidAccountsHistory = ({ props_modelHideshow, props_callModelCloseFunction, props_ConnectionAccountId }) => {
    //  // url params 
    const params = useParams();
    const { permissions, role } = useStateContext();
    const [isLoadingRequest, setLoadingRequest] = useState(false);
    const [isAccountData, setAccountData] = useState(null);
    const connections = useSelector((state) => state.connection);
    useEffect(() => {
    }, [connections])
    useEffect(() => {
        if (props_modelHideshow) {
            getAccountHistory(props_ConnectionAccountId);
        } else {
            setAccountData(null);
        }
    }, [props_modelHideshow])
    const getAccountHistory = (param) => {
        setLoadingRequest(true)
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('plaid/account-history' + queryParams, param)
            .then(({ data }) => {
                if (data.status == 200) {
                    setAccountData(data.accounts);
                    setLoadingRequest(false)
                } else {
                    setLoadingRequest(false)
                }
            }).catch(error => {
                setLoadingRequest(false)
                console.log('error', error);
            })
    }
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Account History</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {
                            isAccountData != null && isAccountData.length > 0
                                ?
                                <>
                                    <div className="" id="table_open">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="text-center">Date</th>
                                                    <th scope="col" className="text-center">Time</th>
                                                    <th scope="col" className="text-center">Available</th>
                                                    <th scope="col" className="text-center">current</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isAccountData.map((account, ind) => (
                                                    <tr key={ind}>

                                                        <td className="text-center">
                                                            {
                                                                account.date
                                                            }

                                                        </td>
                                                        <td className="text-center">
                                                            {
                                                                account.time
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            {account.available}
                                                        </td>
                                                        <td className="text-center">
                                                            {account.current}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                <>
                                    {
                                        isLoadingRequest ?

                                            <>
                                                <div className="card loadingCard loadingCardOverlap inlineLoading">
                                                    <div className="card-body">
                                                        <div className="componentLoading bg-white text-center">

                                                            {
                                                                isLoadingRequest
                                                                    ?
                                                                    <div className="spinner-border m-1" role="status"></div>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>

                                            :
                                            <div>No data found.</div>
                                    }

                                </>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PlaidAccountsHistory;