import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axiosClient from '../axios-client';
import { useStateContext } from "../Context/ContextProvider";
import login_logo from '../images/login_logo.jpg';
import asset from '../images/assest-1.jpg';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux'
import connection, {
    clientDataForDashboard
} from '../store/apps/connection'

const SidebarMenu = (props) => {
    // redux dispatch fucntion
    const dispatch = useDispatch();
    // Access the getUserRole function from props
    const { getUserRole, getIsLoading } = props;
    // navigation function
    const navigate = useNavigate();
    // url params 
    const params = useParams();
    //up state in the default function it is also added
    const { user, setUser, setToken, role, permissions } = useStateContext();

    const [isMenuFixed, setIsMenuFixed] = useState(true);
    const [isPreviousToken, setPreviousToken] = useState(null);
    const [isAdvisorClientView, setAdvisorClientView] = useState(null);
    const connections = useSelector((state) => state.connection)

    const handleResize = () => {
        const bodyElement = document.body;
        if (window.innerWidth < 1024) {
            setIsMenuFixed(false);
            bodyElement.classList.remove('layout-menu-fixed');
            bodyElement.classList.add('device');
        } else {
            setIsMenuFixed(true);
            bodyElement.classList.add('layout-menu-fixed');
            bodyElement.classList.remove('device');
        }
    }
    const resize = () => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }
    const onLogout = (e) => {
        e.preventDefault()

        axiosClient.post('/logout')
            .then(() => {
                setUser({})
                setToken(null)
            })
    }
    const switchToAdmin = async (e) => {
        e.preventDefault()
        let advsiorToken = await localStorage.getItem('ACCESS_TOKEN');
        let postData = {
            token: advsiorToken
        }
        axiosClient.post('/impersonate/logout', postData)
            .then(async () => {
                let token = await localStorage.getItem('PREVIOUS_TOKEN');
                await localStorage.setItem('ACCESS_TOKEN', token);
                // setTimeout(() => {      
                localStorage.removeItem('PREVIOUS_TOKEN');
                navigate('/advisors');
                window.location.reload();
                // }, 1000);
            })
    }
    useEffect(() => {
        const previous_token = localStorage.getItem('PREVIOUS_TOKEN');
        if (previous_token) {
            setPreviousToken(previous_token);
        }
        window.addEventListener('load', handleResize);
        resize()
    }, [getUserRole, getIsLoading])
    useEffect(() => {
        if (connections.clientDataForDashboard && connections.clientDataForDashboard.status == 200) {
            setAdvisorClientView(connections.clientDataForDashboard.user);
        } else if (role == 'advisor' && connections.clientDataForDashboard.status == 401) {
            navigate('/page-404');
        }
    }, [connections.clientDataForDashboard])
    useEffect(() => {
        if (params && params.userId && role == 'advisor') {
            // let obj = {
            //     id: params.userId
            // }
            dispatch(clientDataForDashboard(params.userId));
            // setAdvisorClientView(obj);
        } else {
            setAdvisorClientView(null);
        }
    }, [params, role])
    return (
        <div className={`layout-menu ${isMenuFixed ? 'open' : ''}`}>
            <div className="main-nav">
                <div className="navigation">
                    <div className="menu">

                        {
                            isAdvisorClientView != null && isAdvisorClientView.id
                                ?
                                <div className='clientLogo'>

                                    {isAdvisorClientView.name}
                                </div>
                                :
                                <div className="logo">
                                    <img src={login_logo} alt="logo" />
                                </div>
                        }

                        <ul className="nav" id="nav">
                            {
                                permissions != undefined && permissions.length > 0
                                    ?
                                    <>
                                        {
                                            _.includes(permissions, 'view_dashboard')
                                                ?
                                                <>
                                                    {
                                                        role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                            ?
                                                            <li>
                                                                <span className="nav-link disabled">Dashboard</span>
                                                            </li>
                                                            :
                                                            <li>
                                                                <NavLink 
                                                                // exact
                                                                    to={isAdvisorClientView !== null ? `/dashboard/${isAdvisorClientView.id}` : '/dashboard'}
                                                                    activeclassname="active"
                                                                    className="nav-link"
                                                                    id={isAdvisorClientView !== null ? `dashboard_${isAdvisorClientView.id}` : ''}
                                                                >
                                                                    dashboard
                                                                </NavLink>
                                                            </li>

                                                    }

                                                </>

                                                :
                                                null
                                        }
                                        {
                                            _.includes(permissions, 'view_connections')

                                                ?
                                                <>
                                                    {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                        ?
                                                        <li>
                                                            <span className="nav-link disabled">Connections</span>
                                                        </li>
                                                        :
                                                        <li>
                                                            <NavLink
                                                                to={isAdvisorClientView !== null ? `/connections/${isAdvisorClientView.id}` : '/connections'}
                                                                activeclassname="active"
                                                                className="nav-link"
                                                                id={isAdvisorClientView !== null ? `connections_${isAdvisorClientView.id}` : ''}
                                                            >
                                                                connections
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </>
                                                :
                                                null
                                        }

                                        {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                            ?
                                            <li>
                                                <span className="nav-link disabled">Plaid</span>
                                            </li>
                                            :
                                            <li >
                                                <NavLink to={isAdvisorClientView !== null ? `/plaid/${isAdvisorClientView.id}` : '/plaid'}
                                                    activeclassname="active"
                                                    className="nav-link"
                                                    id={isAdvisorClientView !== null ? `plaid_${isAdvisorClientView.id}` : ''}>
                                                    Plaid
                                                </NavLink>
                                            </li>
                                        }

                                        {
                                            _.includes(permissions, 'view_entities')

                                                ?
                                                <>
                                                    {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                        ?
                                                        <li>
                                                            <span className="nav-link disabled">Entities</span>
                                                        </li>
                                                        :
                                                        <li>
                                                            <NavLink
                                                                to={isAdvisorClientView !== null ? `/entities/${isAdvisorClientView.id}` : '/entities'}
                                                                activeclassname="active"
                                                                className="nav-link"
                                                                id={isAdvisorClientView !== null ? `entities_${isAdvisorClientView.id}` : ''}
                                                            >
                                                                Entities
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </>

                                                :
                                                null
                                        }
                                        {
                                            _.includes(permissions, 'view_manual_inputs')

                                                ?
                                                <>
                                                    {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                        ?
                                                        <li>
                                                            <span className="nav-link disabled">Manual Inputs</span>
                                                        </li>
                                                        :
                                                        <li>
                                                            <NavLink
                                                                to={isAdvisorClientView !== null ? `/manual-inputs/${isAdvisorClientView.id}` : '/manual-inputs'}
                                                                activeclassname="active"
                                                                className="nav-link"
                                                                id={isAdvisorClientView !== null ? `manual-inputs_${isAdvisorClientView.id}` : ''}
                                                            >
                                                                Manual Inputs
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </>

                                                :
                                                null
                                        }
                                        {
                                            _.includes(permissions, 'view_insights')

                                                ? <>
                                                    {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                        ?
                                                        <li>
                                                            <span className="nav-link disabled">Insights</span>
                                                        </li>
                                                        :
                                                        <li>
                                                            <NavLink to={isAdvisorClientView !== null ? `/insights/${isAdvisorClientView.id}` : '/insights'}
                                                                activeclassname="active"
                                                                className="nav-link"
                                                                id={isAdvisorClientView !== null ? `insights_${isAdvisorClientView.id}` : ''}>
                                                                Insights
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </>

                                                :
                                                null

                                        }
                                        {
                                            _.includes(permissions, 'view_settings')

                                                ?
                                                <>
                                                    {role != undefined && role == 'advisor' && isAdvisorClientView == null
                                                        ?
                                                        <li>
                                                            <span className="nav-link disabled">Settings</span>
                                                        </li>
                                                        :
                                                        <li>
                                                            <NavLink to={isAdvisorClientView !== null ? `/settings/${isAdvisorClientView.id}` : '/settings'}
                                                                activeclassname="active"
                                                                className="nav-link"
                                                                id={isAdvisorClientView !== null ? `settings_${isAdvisorClientView.id}` : ''}>
                                                                settings
                                                            </NavLink>
                                                        </li>
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                        {
                                            isPreviousToken == null
                                                ?
                                                <li onClick={onLogout}>
                                                    <NavLink to="/login" activeclassname="active" className="nav-link">
                                                        log out
                                                    </NavLink>
                                                </li>
                                                :
                                                <li onClick={(e) => switchToAdmin(e)}>
                                                    <NavLink to="/advisors" activeclassname="active" className="nav-link">
                                                        Switch To Admin
                                                    </NavLink>
                                                </li>
                                        }


                                        <li><hr className='side-bar-custom'></hr></li>
                                        {
                                            _.includes(permissions, 'view_admins')

                                                ?
                                                <li >
                                                    <NavLink to="/admins" activeclassname="active" className="nav-link">
                                                        Admins
                                                    </NavLink>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            _.includes(permissions, 'view_advisors')

                                                ?
                                                <li >
                                                    <NavLink to="/advisors" activeclassname="active" className="nav-link">
                                                        Advisors
                                                    </NavLink>
                                                </li>
                                                :
                                                null
                                        }
                                        {
                                            role != undefined && role != 'admin' &&
                                                _.includes(permissions, 'view_clients')

                                                ?
                                                <li >
                                                    <NavLink to="/clients" activeclassname="active" className="nav-link">
                                                        Clients
                                                    </NavLink>
                                                </li>
                                                :
                                                null
                                        }
                                    </>
                                    :

                                    null
                            }


                        </ul>

                        <div className="account">
                            <div className="account-img">
                                <img src={asset} alt="img" />
                            </div>
                            <div className="information">
                                <div>
                                    <p>
                                        {/* Your Name */}
                                        {user && user.name ? user.name : 'Your Name'} ({role ? role : ''})
                                    </p>
                                </div>
                                <div>
                                    <a href="#">
                                        Book Meeting
                                        <span>
                                            <i className="fa fa-arrow-right-long"></i>
                                        </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SidebarMenu;