import { useState, useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import axiosClient from "../axios-client";
import { ToastContainer, toast } from "react-toastify";
import * as Icon from "react-feather";

const Signup = () => {
    let { userId, token } = useParams();
    const navigate = useNavigate();
    //state to store our password 
    const [password, setPassword] = useState("");
    const [confirm_password, setConfirm_password] = useState("")
    const [btnLoading, setBtnLoading] = useState(false)
    const [error, setError] = useState("");
  
    const [passwordType, setPasswordType] = useState(false);
    const [confirmPasswordType, setConfirmPasswordType] = useState(false)


    return (
        <div>
        </div>
    );
};

export default Signup;