import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
const CreateAdminUserModel = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    const [isName, setName] = useState('');
    const [isUserEmail, setUserEmail] = useState(null);
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isPassword, setPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    useEffect(() => {
        if (props_modelHideshow) {
        } else {
            setName('');
            setUserEmail(null);
            setIsValidEmail(true);
            setPassword(null);
            setConfirmPassword(null);
            setPasswordError('');
            setConfirmPasswordError('');
        }
    }, [props_modelHideshow])
    const createAnEntityFunc = () => {
        setEntitySubmit(true)
        if (isPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
            return; // Don't submit if passwords don't match or have errors
        }
        let postData = {
            name: isName,
            email: isUserEmail,
            password: isPassword
        }
        axiosClient.post('admin-user/create', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    setEntitySubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setEntitySubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };
    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };
    const handlePasswordChange = (event) => {
        // console.log('evenmt',event.target.value);
        setPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Admin</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                                Email:
                            </label>
                            <input
                                type="email"
                                value={isUserEmail}
                                onChange={handleEmailChange}
                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                id="exampleInputPassword1"
                            />
                            {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">New Password</label>
                            <input type="password"
                                className="form-control"
                                id="password"
                                value={isPassword}
                                onChange={(e) => handlePasswordChange(e)}
                            />
                            {passwordError && <div className="error">{passwordError}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input type="password"
                                className="form-control"
                                id="confirmPassword"
                                onChange={(e) => handleConfirmPasswordChange(e)}
                            />
                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={confirmPasswordError == '' && passwordError == '' && isValidEmail && isName.length > 0 ? false : true}
                        onClick={createAnEntityFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Save</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateAdminUserModel;