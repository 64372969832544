import { createBrowserRouter } from "react-router-dom"
import Login from '../Pages/Login'
import Invitation from '../Pages/Invitation'
import ResetPassword from '../Pages/ResetPassword'
import VerifyUser from '../Pages/VerifyUser'
import ForgotPassword from '../Pages/ForgotPassword'
import { useStateContext } from '../Context/ContextProvider'
import Index from '../Pages/Index'
import Connections from '../Pages/Connections'
import Goals from '../Pages/Goals'
import Account from '../Pages/Account'
import Insights from '../Pages/insights'
import Clients from '../Pages/Clients'
import Entities from '../Pages/entities'
import Setting from '../Pages/Setting'
import ManualInput from '../Pages/ManualInput'
import DefaultLayout from "../Components/DefaultLayout";
import GuestLayout from "../Components/GuestLayout";
import React, { useState } from "react"
import Dashboard from "../Pages/Dashboard"
import PageNotFound from "../Pages/PageNotFound"
import Admins from "../Pages/Admins"
import Advisor from "../Pages/Advisor"
import CustomPlaidLink from "../Pages/plaid"
const router = createBrowserRouter([
    {
        path: '/',
        element: <DefaultLayout />,
        children: [
            {
                path: '/',
                element: <Index />
            },
            {
                path: '/dashboard/:userId?',
                element: <Dashboard />
            },
            {
                path: '/connections/:userId?',
                element: <Connections />
            },
            {
                path: '/entities/:userId?',
                element: <Entities />
            },
            {
                path: '/manual-inputs/:userId?',
                element: <ManualInput />
            },
            {
                path: '/goals',
                element: <Goals />
            },
            {
                path: '/account',
                element: <Account />
            },
            {
                path: '/insights/:userId?',
                element: <Insights />
            },
            {
                path: '/settings/:userId?',
                element: <Setting />
            },
            {
                path: '/clients',
                element: <Clients />
            },
            {
                path: '/admins',
                element: <Admins />
            },
            {
                path: '/advisors',
                element: <Advisor />
            }
            ,
            {
                path: '/plaid/:userId?',
                element: <CustomPlaidLink />
            }
            //
        ]
    },
    {
        path: '/',
        element: <GuestLayout />,
        children: [
            {
                path: '/login',
                element: <Login />
            },
            {
                path: '/invitation/:userId/:token',
                element: <Invitation />
            },
            {
                path: '/forgot-password',
                element: <ForgotPassword />
            },
            {
                path: '/reset-password/:userId/:token',
                element: <ResetPassword />
            },
            {
                path: '/verify/:token',
                element: <VerifyUser />
            },
            {
                path: '/page-404',
                element: <PageNotFound />
            },
        ]
    },
    {
        path: "*",
        element: <PageNotFound />,
      },
])


export default router;

function AuthLayout() {
    const { user } = useStateContext();
    const [userRole, setUserRole] = useState();
    return user.role
}
