import React, { useEffect, useState } from "react";
import CreateEnitityModel from "../Components/Modals/CreateEntityModel";
import { useDispatch, useSelector } from 'react-redux'
import {
    getEntityList
} from '../store/apps/connection'
import { ComponentLoading } from "../Components/ComponentLoading";
import EntititesTable from '../Components/Tables/EntititesTable';
import { useNavigate ,useParams} from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
const Entities = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { permissions,role } = useStateContext();
    // url params 
    const params = useParams();
    const [isShowCreateEntityModel, setShowCreateEntityModel] = useState(false);
    const [isEntityData, setEntityData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isTotalCash, setTotalCash] = useState(0);
    //isTotalCash
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        setIsLoading(true)
        // dispatch(getEntityList());
    }, [dispatch])
    useEffect(() => {
        if (connections.getEntityList && connections.getEntityList.data) {
            setTotalCash(connections.getEntityList.totalCash)
            setEntityData(connections.getEntityList.data);
        }
    }, [connections.getEntityList])
    useEffect(() => {
        if (permissions != undefined) {
            if (_.includes(permissions, 'view_entities')) {
                if (params && params.userId && role == 'advisor') {
                    dispatch(getEntityList(params.userId));
                } else if (role == 'advisor') {
                    navigate('/page-404');
                } else if (role != null) {
                    dispatch(getEntityList());
                }
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    const addEntityFunc = () => {
        setShowCreateEntityModel(true);
    };
    const handleShowEntityModalClose = async () => {
        setShowCreateEntityModel(false);
        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
        dispatch(getEntityList(queryParams));
    };
    const formatBalance = (value) => {
        let checkFloat = parseFloat(value != '' ? value : 0)
        if (checkFloat == 0) {
            return '$0';
        } else {
            const formattedValue = Number(value).toLocaleString('en-US');
            return formattedValue !== 0 ? `$${formattedValue}` : '';
        }
    };
    return (
        <>
            <div className="sub_main_entity">
                <div className="entity_heading">
                    <div>
                        <h4>
                            Entities
                        </h4>
                    </div>
                    <div>
                        {
                            permissions != undefined && _.includes(permissions, 'manage_entities')

                                ?
                                <div className="create_entity">
                                    <a href="#" onClick={() => addEntityFunc()} >Create an Entity</a>
                                </div>
                                :
                                null
                        }
                    </div>

                </div>
                {isLoading ?
                    <>
                        {
                            isEntityData != null && isEntityData.length > 0

                                ?
                                <>
                                    <EntititesTable data={isEntityData} />
                                </>
                                :
                                <div className="entity_table mt-5">
                                    <table className="table table3">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">No.</th>
                                                <th scope="col" className="text-center">Name</th>
                                                <th scope="col" className="text-center">Type</th>
                                                <th scope="col" className="text-center">Assets</th>
                                                <th scope="col" className="text-center">Liability</th>
                                                <th scope="col" className="text-center">Total</th>
                                                <th scope="col" className="text-center">No of Accounts</th>
                                                <th scope="col" className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody><tr style={{ position: 'relative' }}>
                                            <td colSpan={8}>
                                                <div className="card loadingCard loadingCardOverlap inlineLoading">
                                                    <div className="card-body">
                                                        <div className="componentLoading bg-white text-center">
                                                            <div>No data found</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                        }
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="accordion-item cCard">
                                    <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                                        <button className="accordion-button" type="button"
                                            data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapseOne`}
                                            aria-expanded="false" aria-controls={`panelsStayOpen-collapseOne`}>
                                            <div className="mortage_div" >
                                                <span>Cash</span>
                                            </div>
                                        </button>
                                    </h2>
                                    <div id={`panelsStayOpen-collapseOne`} className="accordion-collapse collapse show"
                                        aria-labelledby="panelsStayOpen-headingOne">
                                        <span>
                                            {formatBalance(isTotalCash)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <div className="card loadingCard">
                        <div className="card-body">
                            <ComponentLoading></ComponentLoading>
                        </div>
                    </div>
                }


            </div>
            <CreateEnitityModel props_modelHideshow={isShowCreateEntityModel} props_callModelCloseFunction={handleShowEntityModalClose} />
        </>
    )
}

export default Entities;