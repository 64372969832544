import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
const AdminPasswordUpdate = ({ props_modelHideshow, props_callModelCloseFunction, props_editAdminUser }) => {
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isPassword, setPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    useEffect(() => {
        if (props_modelHideshow) {
        } else {
            setPassword(null);
            setConfirmPassword(null);
            setPasswordError('');
            setConfirmPasswordError('');
        }
    }, [props_modelHideshow])
    const createAnEntityFunc = () => {
        setEntitySubmit(true)
        if (isPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
            setConfirmPasswordError('Passwords do not match.');
            setEntitySubmit(false)
            return; // Don't submit if passwords don't match or have errors
        }
        let postData = {
            id: props_editAdminUser.id,
            password: isPassword
        }
        axiosClient.post('admin-user/change-password', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    setEntitySubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setEntitySubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }
    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };
    const handlePasswordChange = (event) => {
        // console.log('evenmt',event.target.value);
        setPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Set Password</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>

                        <div className="mb-3">
                            <label htmlFor="password" className="form-label">New Password</label>
                            <input type="password"
                                className="form-control"
                                id="password"
                                value={isPassword != null ? isPassword : ''}
                                onChange={(e) => handlePasswordChange(e)}
                            />
                            {passwordError && <div className="error">{passwordError}</div>}
                        </div>
                        <div className="mb-3">
                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                            <input type="password"
                                className="form-control"
                                id="confirmPassword"
                                onChange={(e) => handleConfirmPasswordChange(e)}
                            />
                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={confirmPasswordError == '' && passwordError == '' ? false : true}
                        onClick={createAnEntityFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Update</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AdminPasswordUpdate;