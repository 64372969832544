import React, { useState, useEffect } from "react";
import axiosClient from "../axios-client";
import { useParams } from "react-router-dom";
import login_bg from '../images/login_logo.jpg'
import { toast } from "react-toastify";
const Invitation = () => {
    const params = useParams();
    const [btnLoading, setBtnLoading] = useState(false)
    const [userId, setUserId] = useState(null);
    const [token, setToken] = useState(null);
    const [isNewPassword, setNewPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    useEffect(() => {
        setUserId(params.userId);
        setToken(params.token);
    }, [])
    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };
    const handlePasswordChange = (event) => {
        setNewPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };
    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isNewPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    const onSubmit = (e) => {
        e.preventDefault()

        if (isNewPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
            return; // Don't submit if passwords don't match or have errors
        }
        setBtnLoading(true)
        const payload = {
            userId: userId,
            token: token,
            password: isNewPassword,
        }
        axiosClient.post('invitation/password', payload)
            .then(({ data }) => {
                if (data.status == 200) {
                    setBtnLoading(false)
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    setTimeout(() => {
                        window.location.replace("/login");
                    }, 1000);
                } else {
                    setBtnLoading(false)
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            })
            .catch((err) => {
                setBtnLoading(false)
                const response = err.response;
                toast.error(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    }
    return (
        <> <div className="login d-flex align-items-center">
            <div className="width">
                <div className="login_heading m-auto">
                    <p>
                        Set Password
                    </p>
                </div>
                <form className="login_form d-flex flex-column m-auto" onSubmit={onSubmit}>
                    <div className="mb-4 m-auto">
                        <label htmlFor="password" className="form-label login_label">Password </label>
                        <input type="password" placeholder="Enter your Password" className="form-control login_input" id="newPassword" value={isNewPassword} onChange={(e) => handlePasswordChange(e)} />
                        {passwordError && <div className="error" style={{ fontSize: 14 }}>{passwordError}</div>}
                    </div>
                    <div className="mb-4 m-auto">
                        <label htmlFor="password" className="form-label login_label">Confirm Password </label>
                        <input type="password" placeholder="Enter your Confirm Password" className="form-control login_input" id="confirmPassword" onChange={(e) => handleConfirmPasswordChange(e)} />
                        {confirmPasswordError && <div style={{ fontSize: 14 }} className="error">{confirmPasswordError}</div>}
                    </div>
                    <div className="m-auto">
                        <button type="button" className="signin_btn"
                            onClick={onSubmit}
                            disabled={btnLoading}
                        >
                            {
                                btnLoading ?
                                    (
                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                    ) : (
                                        <>Save</>
                                    )
                            }
                        </button>
                    </div>
                </form>
            </div>
            <div className="width2 d-flex align-items-center">
                <div className="m-auto">
                    <img src={login_bg} alt="img" />
                </div>
            </div>
        </div>

        </>
    )
}

export default Invitation

