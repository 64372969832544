import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import Toggle from 'react-toggle';
let count = 0;
const CreateClientUserModel = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    const [isName, setName] = useState('');
    const [isUserEmail, setUserEmail] = useState(null);
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isPassword, setPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [isClientType, setClientType] = useState('basic-client');
    const [isSetPassswordFields, setPasswordFields] = useState(false);
    const [isSendInvitation, setSendInvitation] = useState(true);
    const [selectedOption, setSelectedOption] = useState('sendInvitation');

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };
    useEffect(() => {
        if (props_modelHideshow) {
        } else {
            setName('');
            setUserEmail(null);
            setIsValidEmail(true);
            setPassword(null);
            setConfirmPassword(null);
            setPasswordError('');
            setConfirmPasswordError('');
            setClientType('basic-client');
            setPasswordFields(false);
            setSendInvitation(true);
        }
    }, [props_modelHideshow])
    const createClientFunc = () => {
        if (isSetPassswordFields) {
            if (isPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
                return; // Don't submit if passwords don't match or have errors
            }
        }
        setEntitySubmit(true)
        if (isPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
            return; // Don't submit if passwords don't match or have errors
        }
        let postData = {
            name: isName,
            email: isUserEmail,
            password: isPassword,
            role : isClientType,
            sendInvitation :  selectedOption == 'sendInvitation' ? true : false
        }
        axiosClient.post('client-user/create', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    setEntitySubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setEntitySubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };
    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };

    const handlePasswordChange = (event) => {
        // console.log('evenmt',event.target.value);
        setPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };
    const handleClientChange = (e) => { setClientType(e.target.value); };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create Client</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="Role" className="form-label">
                                Role:
                            </label>
                            <select
                                className="form-select"
                                id="Role"
                                value={isClientType}
                                onChange={handleClientChange}
                                required
                            >
                                <option value="basic-client">Basic Client</option>
                                <option value="deluxe-client">Deluxe Client</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                                Email:
                            </label>
                            <input
                                type="email"
                                value={isUserEmail}
                                onChange={handleEmailChange}
                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                id="exampleInputPassword1"
                            />
                            {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                        </div>
                        {/* <div className="mb-3 d-flex" key={count}>
                            <label className="d-flex">
                                <Toggle
                                    defaultChecked={isSetPassswordFields}
                                    icons={false}
                                    onChange={(e) => { setPasswordFields(!isSetPassswordFields); setSendInvitation(!isSendInvitation); count = count + 1 }} />
                                <span className="pl-2">Set Password</span>
                            </label>
                            <label className="d-flex">
                                <Toggle
                                    defaultChecked={isSendInvitation}
                                    icons={false}
                                    onChange={() => { setPasswordFields(!isSetPassswordFields); setSendInvitation(!isSendInvitation); count = count + 1 }} />
                                <span className="pl-2">Send Invitation</span>
                            </label>
                        </div> */}
                        <div className="mb-3 d-flex">
                            <label className="d-flex">
                                <input
                                    type="radio"
                                    value="setPassword"
                                    checked={selectedOption === 'setPassword'}
                                    onChange={handleOptionChange}
                                />
                                <span className="pl-2">Set Password</span>
                            </label>
                            <label className="d-flex" style={{marginLeft:'10px'}}>
                                <input
                                    type="radio"
                                    value="sendInvitation"
                                    checked={selectedOption === 'sendInvitation'}
                                    onChange={handleOptionChange}
                                />
                                <span className="pl-2">Send Invitation</span>
                            </label>
                        </div>
                        {
                            selectedOption === 'setPassword'
                                ?
                                <>
                                    <div className="mb-3">
                                        <label htmlFor="password" className="form-label">New Password</label>
                                        <input type="password"
                                            className="form-control"
                                            id="password"
                                            value={isPassword}
                                            onChange={(e) => handlePasswordChange(e)}
                                        />
                                        {passwordError && <div className="error">{passwordError}</div>}
                                    </div>
                                    <div className="mb-3">
                                        <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                        <input type="password"
                                            className="form-control"
                                            id="confirmPassword"
                                            value={isConfirmPassword}
                                            onChange={(e) => handleConfirmPasswordChange(e)}
                                        />
                                        {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                    </div>
                                </>
                                :
                                null
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={confirmPasswordError == '' && passwordError == '' && isValidEmail && isName.length > 0 ? false : true}
                        onClick={createClientFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Save</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateClientUserModel;