import React, { useContext, createRef, useState } from "react";
import { useStateContext } from "../Context/ContextProvider";
import { Link, Navigate } from "react-router-dom";
import axiosClient from "../axios-client";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import login_bg from '../images/login_logo.jpg'


const ForgotPassword = () => {

    const emailRef = createRef()
    const [btnLoading, setBtnLoading] = useState(false)
    
    const onSubmit = (e) => {
        e.preventDefault()
    }



    return (
        <div className="login d-flex align-items-center">
            <div className="width">
                <div className="login_heading m-auto">
                    <p>
                        Forgot Password
                    </p>
                </div>
                <form className="login_form d-flex flex-column m-auto" onSubmit={onSubmit}>
                    <div className="mb-2 m-auto">
                        <label htmlFor="InputEmail1" className="form-label login_label">Email </label>
                        <input ref={emailRef} type="email" className="form-control login_input" id="InputEmail1"
                            aria-describedby="emailHelp" placeholder="Enter your Email" />
                    </div>

                    <div className="mb-4 m-auto">
                        <button type="button" className="signin_btn" onClick={onSubmit} disabled={btnLoading}>
                            {
                                btnLoading ?
                                    (
                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                    ) : (
                                        <>Reset Password</>
                                    )
                            }
                        </button>
                    </div>

                    <div className="mb-4 m-auto">
                        <div className="text-center">
                            <p className="text-dark">
                                <span>Already registered? </span>
                                <Link to="/login" className="ml-2">Sign In</Link>
                            </p>
                        </div>
                    </div>
                </form>
            </div>
            <div className="width2 d-flex align-items-center">
                <div className="m-auto">
                    <img src={login_bg} alt="img" />
                </div>
            </div>

        </div>
    )
}

export default ForgotPassword

