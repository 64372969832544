import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    addConnectionDetails
} from '../../store/apps/connection'
import { useDispatch } from 'react-redux'
import axiosClient from "../../axios-client";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
let count = 'zero';
const AddConnectionModal = ({ props_modelHideshow, props_callModelCloseFunction, props_autoCloseConnectionModel, props_ReconnectConnection }) => {
    const dispatch = useDispatch();
    // url params 
    const params = useParams();
    const { role } = useStateContext();
    const [isLoader, setLoader] = useState(false);
    const [data, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [isLoadIframe, setLoadIframe] = useState(false);
    const [isIframeUrl, setIframeUrl] = useState('');
    useEffect(() => {
        if (props_modelHideshow) {
            count = 'zero';
            setSearchQuery('');
            setLoadIframe(false);
            setLoader(false);
            if (props_ReconnectConnection != null) {
                selectInstitute(props_ReconnectConnection);
            }
        }
    }, [props_modelHideshow])
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axiosClient.get('/search', {
                    params: { query: searchQuery }
                });
                if (response.data.length > 0) {
                    setData(response.data);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [searchQuery]);
    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };
    window.addEventListener('message', async function (e) {
        if (e.data.step === 'COMPONENT_LOAD_CREDENTIAL' || e.data.cached === false) {
            if (isLoader) {
                setLoader(false);
            }
        }
        if (e.data.loginId) {
            if (count == 'zero') {
                count = 'one';
                let postData = {
                    institution: e.data.institution,
                    loginId: e.data.loginId,
                    requestId: e.data.requestId
                }
                let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.post('connection/save/details'+queryParams, postData)
                    .then(({ data }) => {
                        console.log('connnections details save');
                        dispatch(addConnectionDetails(postData));
                        props_autoCloseConnectionModel();
                    }).catch(error => {
                        console.log('error occurd connections details save');
                        props_autoCloseConnectionModel();
                    })

            }
        }
    });
    const selectInstitute = (params) => {
        //Check flinks connection with live or sandbox accounts
        if (process.env.REACT_APP_IS_FLINKS_LIVE == 'true') {
            if (params.type == 'banking') {
                let url = `https://blackstarwealthbanking-iframe.private.fin.ag/v2/Credential/${params.instituteId}?backEnable=false`;
                setIframeUrl(url);
            } else {
                let url = `https://blackstarwealth-iframe.private.fin.ag/v2/Credential/${params.instituteId}backEnable=false`
                setIframeUrl(url);
            }
        } else {
            if (params.type == 'banking') {
                let url = `https://blackstarwealth2507.connect.flinks.dev/v2/Credential/14?demo=true&productType=banking`;
                setIframeUrl(url);
            } else {
                let url = `https://demo.flinks.com/v2/Credential/999999999?demo=true&productType=investment`
                setIframeUrl(url);
            }
        }
        setLoadIframe(true);
        setLoader(true);
    }

    const removeProtocolFromUrl = (url) => {
        if (!url || typeof url !== 'string') {
            return ''; // Return an empty string if the input is null or not a string
        }
        return url.replace(/^(https?:\/\/)?(www\.)?/i, '').replace(/\/.*$/, '');
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="modal-md"
            >
                <Modal.Body>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                    {
                        !isLoadIframe ?
                            <div className="modal-body custom_body me-4">
                                <div className="modal_hading">
                                    <div className="lock_icon">
                                        <i className="fa fa-lock"></i>
                                    </div>
                                    <div>
                                        <div>
                                            <h1>
                                                Connect to your institution
                                            </h1>
                                            <p>
                                                Select an institution below to authorize a secure connection
                                            </p>
                                        </div>
                                        <div className="my-3">
                                            <input type="text" className="form-control custom-shadow" id="Search" value={searchQuery} onChange={handleSearch} placeholder="Search..." />
                                        </div>
                                        <div className="mt-4">
                                            {data.map(item => (
                                                <div className="my-3">
                                                    <button className="bank_details" onClick={() => { selectInstitute(item) }}>
                                                        <div className="bank_image">
                                                            {
                                                                item.logo != '' && item.logo != null
                                                                    ?
                                                                    <img width="100%" src={item.logo}
                                                                        alt="" />

                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className="bank_name">
                                                            <h6>
                                                                {item.name}
                                                            </h6>
                                                            <p>
                                                                {removeProtocolFromUrl(item.url)}
                                                            </p>
                                                        </div>
                                                        <div className="ms-auto ">
                                                            <span>
                                                                <i className="fa fa-chevron-right"></i>
                                                            </span>
                                                        </div>
                                                    </button>
                                                </div>
                                            ))}

                                        </div>
                                    </div>

                                </div>
                            </div>

                            :
                            <div className="container">
                                <div className="row gutters">
                                    <div className="col-sm-12">

                                        <div className="col-sm-12">
                                            <iframe className="flinksconnect" height="760"
                                                src={isIframeUrl}>
                                            </iframe>
                                            {isLoader ? (
                                                <div className="card loadingCard loadingCardOverlap">
                                                    <div className="card-body">
                                                        <div className="componentLoading bg-white text-center">
                                                            <div className="spinner-border m-1" role="status"></div>
                                                            <div className='mt-1'>
                                                                <span className='text-dark'>Loading...</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    }
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AddConnectionModal;