import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import {
    getUserData
} from '../store/apps/connection'
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
let count = 0;
const Setting = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const { permissions, role } = useStateContext();
    const [isSettingData, setSettingData] = useState(null);
    const [isUserName, setUserName] = useState(null);
    const [isCode, setCode] = useState(null);
    const [isUserEmail, setUserEmail] = useState(null);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isVerificationEmailSend, setVerificationEmailSend] = useState(true);
    const [isSubmitOtp, setSubmitOtp] = useState(true);
    const [isCurrentPassword, setCurrentPassword] = useState(null);
    const [isNewPassword, setNewPassword] = useState(null);
    const [isConfirmPassword, setConfirmPassword] = useState(null);
    const [passwordError, setPasswordError] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');

    const validatePasswordStrength = (password) => {
        // Implement your password strength validation logic here
        return password.length >= 8; // Example: Password must be at least 8 characters
    };

    const handlePasswordChange = (event) => {
        setNewPassword(event.target.value);
        const validPassword = validatePasswordStrength(event.target.value);

        if (!validPassword) {
            setPasswordError('Password must be at least 8 characters long.');
        } else {
            setPasswordError('');
        }
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
        if (isNewPassword !== event.target.value) {
            setConfirmPasswordError('Passwords do not match.');
        } else {
            setConfirmPasswordError('');
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (isNewPassword !== isConfirmPassword || passwordError || confirmPasswordError) {
            return; // Don't submit if passwords don't match or have errors
        }
        let postData = {
            currentPassword: isCurrentPassword,
            newPassword: isNewPassword
        }
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('change-password' + queryParams, postData)
            .then(({ data }) => {
                setCurrentPassword(null);
                setNewPassword(null);
                setConfirmPassword(null);
                toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            }).catch(error => {
                toast.success(error.response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    };
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        // dispatch(getUserData());
    }, [dispatch])
    useEffect(() => {
        if (permissions != undefined) {
            if (_.includes(permissions, 'view_settings')) {
                if (params && params.userId && role == 'advisor') {
                    dispatch(getUserData(params.userId));
                } else if (role == 'advisor') {
                    navigate('/page-404');
                } else if (role != null) {
                    dispatch(getUserData());
                }
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    useEffect(() => {
        if (connections.getUserData && connections.getUserData.role) {
            setSettingData(connections.getUserData.user);
            setUserName(connections.getUserData.user.name);
            setUserEmail(connections.getUserData.user.email);
        }
    }, [connections])
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };
    const sendOtpForEmailAndNameUpdate = () => {
        setSubmitOtp(false);
        if (isSettingData.name == isUserName && isSettingData.email == isUserEmail) {
            console.log('email or name not change');
            setSubmitOtp(true);
        } else {
            let postData = {
                email: isUserEmail,
                name: isUserName
            }
            let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
            axiosClient.post('otp/send' + queryParams, postData)
                .then(({ data }) => {
                    count = count + 1;
                    if (data.message == "Verification email sent!") {
                        setCode(null);
                        setSubmitOtp(true);
                        setVerificationEmailSend(false);
                        toast.success(data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    } else {
                        setSubmitOtp(true);
                        let queryParams = params && params.userId && role == 'advisor' ? params.userId : '';
                        dispatch(getUserData(queryParams));
                        toast.success(data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    }
                }).catch(error => {
                    toast.success(error.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                })
        }
    }
    const checkOtpCodeAndUpdateNameAndEmail = () => {
        setSubmitOtp(false);
        let postData = {
            email: isUserEmail,
            name: isUserName,
            code: isCode
        }
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('otp/check' + queryParams, postData)
            .then(({ data }) => {
                let queryParams = params && params.userId && role == 'advisor' ? params.userId : '';
                setSubmitOtp(true);
                dispatch(getUserData(queryParams));
                setVerificationEmailSend(true);
                toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            }).catch(error => {
                setSubmitOtp(true);
                toast.success(error.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    }
    const handleCodeChange = (e) => {
        const inputCode = e.target.value;
        const numericCode = inputCode.replace(/\D/g, ''); // Remove non-numeric characters
        const truncatedCode = numericCode.slice(0, 6); // Limit to 6 digits
        setCode(truncatedCode);
    };

    return (
        <>
            <div className="setting">

                <div className="setting_form">

                    <div className="setting_subForm">
                        <div className="row">
                            <div className="col-12">
                                <div className="profile_update">
                                    <h4>
                                        Profile information
                                    </h4>
                                    <p>
                                        Update your account's profile information and email address.
                                    </p>
                                </div>
                                <div className="setting_formUpdate ">
                                    <form className="setting_form_input my-3" onSubmit={e => e.preventDefault()}>
                                        {
                                            isVerificationEmailSend

                                                ?
                                                <>
                                                    <div className="mb-2">
                                                        <label htmlFor="exampleInputEmail1" className="form-label"> Name:</label>
                                                        <input type="text" value={isUserName} onChange={e => setUserName(e.target.value)} className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" />
                                                    </div>
                                                    <div className="mb-3">
                                                        <label htmlFor="exampleInputPassword1" className="form-label">Email:</label>
                                                        <input
                                                            type="email"
                                                            value={isUserEmail}
                                                            onChange={handleEmailChange}
                                                            className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                                            id="exampleInputPassword1"
                                                        />
                                                        {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                                                    </div></>
                                                :
                                                <>
                                                    <div className="additional_setting">
                                                        <p>  We have sent you a verification email at
                                                            {' ' + isUserEmail}. Please enter the pin code below.
                                                        </p>
                                                    </div>
                                                    <div className="mb-3" key={count}>
                                                        <label htmlFor="Code-input" className="form-label">Code:</label>
                                                        <input
                                                            type="text"
                                                            value={isCode}
                                                            onChange={handleCodeChange}
                                                            className="form-control"
                                                            id="Code-input"
                                                            aria-describedby="codeHelp"
                                                            maxLength="6"
                                                        />
                                                    </div>
                                                </>
                                        }
                                        <div className="save_btn">
                                            {
                                                permissions != undefined && _.includes(permissions, 'manage_settings')
                                                    ?
                                                    <button type="submit" disabled={isValidEmail == true && isUserName != null && isUserName.length > 0 && isSubmitOtp ? false : true} onClick={isVerificationEmailSend ? sendOtpForEmailAndNameUpdate : checkOtpCodeAndUpdateNameAndEmail
                                                    }>Save</button>
                                                    :
                                                    null
                                            }

                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div className="col-12">
                                <div className="profile_update">
                                    <h4>
                                        Update password
                                    </h4>
                                    <p>
                                        Ensure your account is using a long, random password to stay secure
                                    </p>
                                </div>
                                <div className="setting_formUpdate setting_formUpdate_2">
                                    <form className="setting_form_input setting_form_input_2 my-3" onSubmit={handleSubmit}>
                                        <div className="mb-2">
                                            <label htmlFor="currentPassword" className="form-label">Current Password</label>
                                            <input type="password" className="form-control" id="currentPassword" value={isCurrentPassword} onChange={(e) =>
                                                setCurrentPassword(e.target.value)
                                            } />
                                        </div>
                                        <div className="mb-2">
                                            <label htmlFor="newPassword" className="form-label">New Password</label>
                                            <input type="password" className="form-control" id="newPassword" value={isNewPassword} onChange={(e) => handlePasswordChange(e)} />
                                            {passwordError && <div className="error">{passwordError}</div>}
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="confirmPassword" className="form-label">Confirm Password</label>
                                            <input type="password" className="form-control" id="confirmPassword" onChange={(e) => handleConfirmPasswordChange(e)} />
                                            {confirmPasswordError && <div className="error">{confirmPasswordError}</div>}
                                        </div>
                                        <div className="save_btn">
                                            {
                                                permissions != undefined && _.includes(permissions, 'manage_settings')
                                                    ?
                                                    <button type="submit">Save</button>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </form>
                                </div>

                            </div>
                            <div className="col-12 mt-3">
                                <div className="profile_update">
                                    <h4>
                                        two factor authentication
                                    </h4>
                                    <p>
                                        add additional security to your account using two factor authentication
                                    </p>
                                </div>
                                <div className="setting_formUpdate setting_formUpdate_2">
                                    <div className="additional_setting">
                                        <h2>
                                            You have not enabled two factor authentication.
                                        </h2>
                                        <p>
                                            When two factor authentication is enabled, you will be prompted for a
                                            secure, random token during authentication. You may retrieve this token from
                                            your phone's Google Authenticator application.
                                        </p>
                                    </div>
                                    <div className="save_btn">
                                        {
                                            permissions != undefined && _.includes(permissions, 'manage_settings')
                                                ?
                                                <button type="submit">Enable</button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>


                            </div>
                            <div className="col-12 mt-3">
                                <div className="profile_update">
                                    <h4>
                                        browser sessions
                                    </h4>
                                    <p>
                                        Manage and logout your active sessions on other browsers and devices
                                    </p>
                                </div>
                                <div className="setting_formUpdate setting_formUpdate_2">
                                    <div className="additional_setting">

                                        <p>
                                            If necessary, you may log out of all of your other browser sessions across
                                            all of your devices. Some of your recent sessions are listed below; however,
                                            this list may not be exhaustive. If you feel your account has been
                                            compromised, you should also update your password.
                                        </p>
                                        <div className="d-flex current_device">
                                            <div className="me-2">
                                                <i className="fa fa-desktop fs-4"></i>
                                            </div>
                                            <div>
                                                <h6>
                                                    OS - Chorme
                                                </h6>
                                                <p>
                                                    127.0.0.1 <span>This device</span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="save_btn save_btn_2">
                                        {
                                            permissions != undefined && _.includes(permissions, 'manage_settings')
                                                ?
                                                <button type="submit">
                                                    log out other browser sessions

                                                </button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                            </div>
                            <div className="col-12 mt-3 pb-4">
                                <div className="profile_update">
                                    <h4>
                                        delete account
                                    </h4>
                                    <p>
                                        Permanently delete your account
                                    </p>
                                </div>
                                <div className="setting_formUpdate setting_formUpdate_2">
                                    <div className="additional_setting">

                                        <p>
                                            Once your account is deleted, all of its resources and data will be
                                            permanently deleted. Before deleting your account, please download any data
                                            or information that you wish to retain.
                                        </p>

                                    </div>
                                    <div className="save_btn save_btn_3">
                                        {
                                            permissions != undefined && _.includes(permissions, 'manage_settings')
                                                ?
                                                <button type="submit">
                                                    delete account
                                                </button>
                                                :
                                                null
                                        }
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default Setting;