import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
let count = 0;
const Account = () => {
    const { user, role, notification } = useStateContext();
    const navigate = useNavigate();
    
    return (
        <>
        </>
    )
}

export default Account;