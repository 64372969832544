import { useEffect, useState } from 'react'
import axios from "axios";
import { usePlaidLink } from "react-plaid-link";
import axiosClient from '../axios-client';
import { toast } from "react-toastify";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import * as Icon from "react-feather"
import { ComponentLoading } from "../Components/ComponentLoading";
import Swal from "sweetalert2";
import PlaidAccountsHistory from "../Components/Modals/PlaidAccountsHistory";
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
let count = 'one';
function CustomPlaidLink() {
    //  // url params 
    const params = useParams();
    const { permissions, role } = useStateContext();
    const [isLoading, setIsLoading] = useState(false);
    const [linkToken, setLinkToken] = useState();
    const [publicToken, setPublicToken] = useState();
    const [isConnections, setConnections] = useState(null);
    const [isAcccountHistoryProps, setAcccountHistoryProps] = useState({});
    const [isAccountHistoryModel, setAccountHistoryModel] = useState(false);
    const [isCollapse, setCollapse] = useState({
        ind: 0,
        status: false
    });

    useEffect(() => {
        if (count == 'one' && role != null) {
            count = 'zero';
            async function fetch() {
                let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
                const response = await axiosClient.post("/create_link_token" + queryParams);
                setLinkToken(response.data.link_token);
            }
            let queryParams = params && params.userId && role == 'advisor' ? params.userId : '';
            getPlaidConnection(queryParams);
            fetch();

        }
    }, [role]);
    const getPlaidConnection = async () => {
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        const response = await axiosClient.get("/plaid/connection/get" + queryParams);
        setConnections(response.data.connections);
        setIsLoading(true);
        count = 'one';
    }
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            saveConnectionAccount(metadata);
            setPublicToken(public_token);
        },
    });
    const saveConnectionAccount = async (plaidMetaData) => {
        setIsLoading(false);
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        const response = await axiosClient.post("/plaid/connection-account/save" + queryParams, plaidMetaData);
        if (response.data.status == 200) {
            toast.success(response.data.message, {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
            });
            let queryParams = params && params.userId && role == 'advisor' ? params.userId : '';
            getPlaidConnection(queryParams);
        } else {
            setIsLoading(true);
        }
    }
    const refreshConnectionAccount = async (name) => {
        setIsLoading(false);
        let postData = {
            name: name
        }
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        const response = await axiosClient.post("/plaid/connection/refresh" + queryParams, postData);
        if (response.data.status == 200) {
            toast.success(response.data.message, {
                position: "bottom-right",
                autoClose: 1500,
                theme: "colored",
            });
            let queryParams = params && params.userId && role == 'advisor' ? params.userId : ''
            getPlaidConnection(queryParams);
        } else {
            setIsLoading(true);
        }
    }
    const showAccountHistory = (accountId) => {
        let accountDetails = {
            accountId: accountId,
        }
        setAcccountHistoryProps(accountDetails);
        setAccountHistoryModel(true);
    }
    const handleAccountHistoryModalClose = () => {
        setAccountHistoryModel(false);
    }
    const deletePlaidConnection = (name) => {
        Swal.fire({
            title: "Are you sure want to delete?",
            icon: "question",
            confirmButtonColor: "#f32013",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    name: name,
                };
                let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.post("plaid/connection/delete" + queryParams, payload).then((response) => {
                    if (response.data.status == 200) {
                        setIsLoading(false);
                        toast.success(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                        let queryParams = params && params.userId && role == 'advisor' ? params.userId : '';
                        getPlaidConnection(queryParams);
                    } else {
                        toast.error(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    }
                });
            } else {
            }
        });
    }
    return (
        <>
            <div className="page-content">
                {isLoading ?
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="pt-3 pl-3 pr-3 pb-3">
                                <div className="card-toolbar">
                                    <div
                                        className="d-flex justify-content-center m-auto"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className="pl-3 pr-3 pb-3">
                                <div className="entity_btn">
                                    <div className="create_entity">
                                        <a onClick={() => open()} disabled={!ready}>Add Connections</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {
                            isConnections != null && isConnections.length > 0
                                ?
                                <>
                                    {isConnections.map((connection, index) => (
                                        <div className="all_bank_data" key={index}>
                                            <div className="bank_img">
                                                <div className="bank_logo_custom">
                                                    <div className="logo_width">
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            overlay={<Tooltip id="example-tooltip">
                                                                {connection.name}
                                                            </Tooltip>}
                                                        >
                                                            {
                                                                connection.logo != null
                                                                    ?

                                                                    <div className="bank_icon">
                                                                        <img width="30%" src={connection.logo} alt={connection.institution} />
                                                                    </div>
                                                                    :
                                                                    <p className="mt-3 fw-semibold">{connection.name}</p>

                                                            }
                                                        </OverlayTrigger>


                                                    </div>
                                                    <div className="mt-3 last_seen">
                                                        <div>

                                                            {/* {
                                                                connection.connectionStatus

                                                                    ?
                                                                    <div className="active_btn">
                                                                        active
                                                                    </div>
                                                                    :
                                                                    <div className="reconnected_btn" onClick={() => addConnectionFunc(connection.institutionId, connection.accounts.length > 0 ? 'banking' : 'investment')} >
                                                                        Reconnect
                                                                    </div>

                                                            } */}
                                                            <div className="active_btn">
                                                                active
                                                            </div>

                                                            <div>
                                                                <p style={{ color: '#364B7F' }} className="mt-1 fw-semibold">
                                                                    {/* Last updated:  {connection.updatedTime + ' ago'} */}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="icons icons_first">
                                                    <div className="Btn_dropdown">
                                                        <Dropdown className="custom-dropdown" >
                                                            <Dropdown.Toggle id="dropdown-basic">
                                                                <i className="fa fa-ellipsis-vertical" ></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu>
                                                                <Dropdown.Item href="#" onClick={() => {
                                                                    deletePlaidConnection(connection.name);
                                                                }}>Delete</Dropdown.Item>
                                                                <Dropdown.Item href="#" onClick={() => {
                                                                    refreshConnectionAccount(connection.name);
                                                                }}>Refresh</Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div>
                                                    <div>
                                                        <a href="#"
                                                            id="show_table" onClick={() => {
                                                                let value = {};
                                                                if (isCollapse.ind === index) {
                                                                    value = {
                                                                        ind: index,
                                                                        status: !isCollapse.status
                                                                    }
                                                                    setCollapse(value);
                                                                } else {
                                                                    value = {
                                                                        ind: index,
                                                                        status: true
                                                                    }
                                                                    setCollapse(value);
                                                                }

                                                            }} >
                                                            <div className="select ">
                                                                {
                                                                    isCollapse && isCollapse.status === true && isCollapse.ind === index

                                                                        ?
                                                                        <i className="fa fa-caret-right" id="rotate"></i>
                                                                        :
                                                                        <i className="fa fa-caret-right rotate" id="rotate"></i>
                                                                }

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isCollapse && isCollapse.status === true && isCollapse.ind === index
                                                    ?
                                                    <>
                                                        {
                                                            connection.accounts && connection.accounts.length > 0

                                                                ?
                                                                <>
                                                                    <div className="" id="table_open">
                                                                        <table className="table ">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" className="text-center">No.</th>
                                                                                    <th scope="col" className="text-center">Name</th>
                                                                                    <th scope="col" className="text-center">Offical Name</th>
                                                                                    <th scope="col" className="text-center">Subtype</th>
                                                                                    <th scope="col" className="text-center">Type</th>
                                                                                    <th scope="col" className="text-center">Available</th>
                                                                                    <th scope="col" className="text-center">Current</th>
                                                                                    <th scope="col" className="text-center">History</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {connection.accounts.map((account, ind) => (
                                                                                    <tr key={ind}>
                                                                                        <td className="text-center">
                                                                                            {ind + 1}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.name ? account.name : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.officialName ? account.officialName : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.subtype ? account.subtype : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.type ? account.type : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.available ? account.available : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            {account && account.current ? account.current : 'N/A'}
                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <Icon.Clock
                                                                                                onClick={() => showAccountHistory(account.id)}
                                                                                            />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))}
                                </>
                                :
                                // When no connections found
                                <div className="mt-5">
                                    <h1 className="fancy-heading">
                                        Connections
                                    </h1>
                                    <p className="fancy-pragh">No connections found. Click add connections to add another connection.</p>
                                </div>
                        }

                    </>
                    :
                    <div className="card loadingCard">
                        <div className="card-body">
                            <ComponentLoading></ComponentLoading>
                        </div>
                    </div>}

            </div >
            <PlaidAccountsHistory props_modelHideshow={isAccountHistoryModel} props_callModelCloseFunction={handleAccountHistoryModalClose} props_ConnectionAccountId={isAcccountHistoryProps} />
        </>
    );
}

export default CustomPlaidLink;