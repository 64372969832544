import React, { useState } from "react";
import { useTable, useSortBy } from 'react-table';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as Icon from "react-feather";
import Swal from "sweetalert2";
import axiosClient from "../../axios-client";
import { useDispatch } from 'react-redux'
import {
    getEntityList
} from '../../store/apps/connection';
import { toast } from "react-toastify";
import EditEntityModel from "../Modals/EditEntityModel";
import { useStateContext } from "../../Context/ContextProvider";
import _ from 'lodash';
import { useNavigate ,useParams} from 'react-router-dom'
function EntititesTable({ data }) {
    const dispatch = useDispatch();
    // url params 
    const params = useParams();
    const { permissions ,role} = useStateContext();
    const [isEditProps, setEditProps] = useState({});
    const [isShowEditEntityModel, setShowEditEntityModel] = useState(false);
    const columns = React.useMemo(
        () => [
            {
                Header: 'No.',
                accessor: (row, index) => index + 1,
            },
            {
                Header: 'Name',
                accessor: 'name',
            },
            {
                Header: 'Type',
                accessor: 'type',
            },
            {
                Header: 'Assets',
                accessor: 'assets',
                Cell: ({ row }) => (
                    <div className="text-center">{formatBalance(row.values.assets)}</div>
                ),
            },
            {
                Header: 'Liability',
                accessor: 'liability',
                Cell: ({ row }) => (
                    <div className="text-center">{formatBalance(row.values.liability)}</div>
                ),
            },
            {
                Header: 'Total',
                accessor: 'total',
                Cell: ({ row }) => (
                    <div className="text-center">{formatBalance(row.values.total)}</div>
                ),
            },
            {
                Header: 'No of Accounts',
                accessor: 'accountCount',
            },
            {
                Header: 'Action',
                accessor: 'id',
                disableSortBy: true,
                Cell: ({ row }) => (
                    <>
                        {
                            permissions != undefined && _.includes(permissions, 'manage_entities')

                                ?
                                <div className="actionButtons">
                                    <div className="actionButtons">
                                        <OverlayTrigger
                                            trigger={['hover', 'focus']}
                                            placement="top"
                                            overlay={<Tooltip id="example-tooltip">Edit</Tooltip>}
                                        >
                                            <Icon.Edit onClick={() => editEntityModal(row)} width={'16'} strokeOpacity={'0.7'} />

                                        </OverlayTrigger>
                                        <span style={{ marginLeft: '10px' }}>
                                            <OverlayTrigger
                                                trigger={['hover', 'focus']}
                                                placement="top"
                                                overlay={<Tooltip id="example-tooltip">Delete</Tooltip>}

                                            >
                                                <Icon.Trash onClick={() => deleteEntity(row)} width={'16'} strokeOpacity={'0.7'} />

                                            </OverlayTrigger>
                                        </span>
                                    </div>
                                </div>
                                :
                                null
                        }
                    </>

                ),
            },
        ],
        []
    );
    const formatBalance = (value) => {
        let checkFloat = parseFloat(value != '' ? value : 0)
        if (checkFloat == 0) {
            return '$0';
        } else {
            const formattedValue = Number(value).toLocaleString('en-US');
            return formattedValue !== 0 ? `$${formattedValue}` : '';
        }
    };
    const editEntityModal = (params) => {
        setEditProps(params.values)
        setShowEditEntityModel(true);
    }
    const deleteEntity = async (row) => {
        Swal.fire({
            title: "Are you sure want to delete?",
            icon: "question",
            confirmButtonColor: "#f32013",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.delete("entity/" + row.values.id + queryParams).then((res) => {
                    let queryParams = params && params.userId &&  role == 'advisor' ?  params.userId : '';
                    dispatch(getEntityList(queryParams));
                    toast.success(res.data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                });
            }
        });
    };
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
        },
        useSortBy
    );
    const handleShowEditEntityModalClose = () => {
        let queryParams = params && params.userId &&  role == 'advisor' ? params.userId : '';
        dispatch(getEntityList(queryParams));
        setEditProps({});
        setShowEditEntityModel(false);
    };
    return (
        <>
            <div className="entity_table mt-5">
                <table className="table table3" {...getTableProps()}>
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map(column => (
                                    <th className="text-center" {...column.getHeaderProps(column.getSortByToggleProps())}>
                                        {column.render('Header')}
                                        <span>
                                            {column.isSorted
                                                ? column.isSortedDesc
                                                    ? ' 🔽'
                                                    : ' 🔼'
                                                : ''}
                                        </span>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map(row => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td className="text-center" {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                        );
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
            <EditEntityModel props_modelHideshow={isShowEditEntityModel} props_callModelCloseFunction={handleShowEditEntityModalClose}
                props_editEntity={isEditProps}

            />
        </>
    );
}

export default EntititesTable;
