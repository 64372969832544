import React, { createRef, useState } from "react";
import axiosClient from "../axios-client";
import { useStateContext } from "../Context/ContextProvider";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import login_bg from '../images/login_logo.jpg'

const Login = () => {

    const emailRef = createRef()
    const passwordRef = createRef()
    const { setUser, setToken, setRole ,setPermissions } = useStateContext();
    const [btnLoading, setBtnLoading] = useState(false)
    const [passwordType, setPasswordType] = useState(false)
    const onSubmit = (e) => {
        e.preventDefault()
        setBtnLoading(true)
        const payload = {
            email: emailRef.current.value,
            password: passwordRef.current.value,
        }
        axiosClient.post('/login', payload)
            .then(({ data }) => {
                setUser(data.user)
                setRole(data.role)
                setToken(data.token);
                setPermissions(data.permissions);
                setBtnLoading(false)
                toast.success("Login Successful", {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                window.location.replace("/");
            })
            .catch((err) => {
                setBtnLoading(false)
                const response = err.response;
                toast.error(response.data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    }

    return (
        <div className="login d-flex align-items-center">
            <div className="width">
                <div className="login_heading m-auto">
                    <p>
                        welcome back
                    </p>
                </div>
                <form className="login_form d-flex flex-column m-auto" onSubmit={onSubmit}>
                    <div className="mb-2 m-auto">
                        <label htmlFor="InputEmail1" className="form-label login_label">Email </label>
                        <input ref={emailRef} type="email" className="form-control login_input" id="InputEmail1"
                            aria-describedby="emailHelp" placeholder="Enter your Email" />
                    </div>
                    <div className="mb-4 m-auto">
                        <label htmlFor="password" className="form-label login_label">Password </label>
                        <input ref={passwordRef} type={passwordType ? ("text") : ("password")} className="form-control login_input" id="psd" aria-describedby="psd"
                            placeholder="Enter your Password" />
                        <div className="inputIcons">
                        </div>
                        <div className="forgot d-flex justify-content-end mt-1">
                            <Link to="/forgot-password" className="ml-2 forgot_password">Forgot password?</Link>
                        </div>
                    </div>
                    <div className="m-auto">
                        <button type="button" className="signin_btn" onClick={onSubmit} disabled={btnLoading}>
                            {
                                btnLoading ?
                                    (
                                        <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                    ) : (
                                        <>Sign In</>
                                    )
                            }
                        </button>
                    </div>
                </form>
            </div>
            <div className="width2 d-flex align-items-center">
                <div className="m-auto">
                    <img src={login_bg} alt="img" />
                </div>
            </div>
        </div>
    )
}

export default Login

