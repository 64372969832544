import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
const EditAdminUserModel = ({ props_modelHideshow, props_callModelCloseFunction, props_editAdminUser }) => {
    const [isName, setName] = useState('');
    const [isUserEmail, setUserEmail] = useState('');
    const [isAdminUserSubmit, setAdminUserSubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    useEffect(() => {
        if (props_modelHideshow) {
            setName(props_editAdminUser.name);
            setUserEmail(props_editAdminUser.email);
        } else {
            setName('');
            setUserEmail('');
            setIsValidEmail(true);
        }
    }, [props_modelHideshow])
    const editAdminUserFunc = () => {
        setAdminUserSubmit(true)
        let postData = {
            id: props_editAdminUser.id,
            name: isName,
            email: isUserEmail
        }
        axiosClient.post('admin-user/edit', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    setAdminUserSubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setAdminUserSubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setAdminUserSubmit(false);
            })
    }
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Admin</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                                Email:
                            </label>
                            <input
                                type="email"
                                value={isUserEmail}
                                onChange={handleEmailChange}
                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                id="exampleInputPassword1"
                            />
                            {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={isValidEmail && isName.length > 0 ? false : true}
                        onClick={editAdminUserFunc}
                    >
                        {
                            isAdminUserSubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Update</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditAdminUserModel;