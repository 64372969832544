import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';
import axiosClient from "../../axios-client";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
let chartData = {
    labels: [],
    datasets: [
        {
            label: "Data",
            data: [],
            borderColor: "rgb(31, 94, 90)",
            backgroundColor: "rgba(31, 94, 90, 0.2)",
            pointBackgroundColor: "rgb(31, 94, 90)",
            pointBorderColor: "#fff",
            pointHoverBackgroundColor: "#fff",
            pointHoverBorderColor: "rgb(31, 94, 90)",
            fill: true,
        },
    ],
};
let chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
        y: {
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            display: false,
            position: 'top',
        },
        tooltip: {
            enabled: true,
        },
    },
};
const LineChart = () => {
    // url params 
    const params = useParams();
    const {  role } = useStateContext();
    const [isGraphDataChartData, setGraphDataChartData] = useState({
        labels: [],
        datasets: [
            {
                label: "Data",
                data: [],
                borderColor: "rgb(31, 94, 90)",
                backgroundColor: "rgba(31, 94, 90, 0.2)",
                pointBackgroundColor: "rgb(31, 94, 90)",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "rgb(31, 94, 90)",
                fill: true,
            },
        ],
    });
    const [isGraphDatachartOptions, setGraphDatachartOptions] = useState({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'top',
            },
            tooltip: {
                enabled: true,
            },
        },
    });
    useEffect(() => {
        getDashoardGraphReport();
    }, [])
    const getDashoardGraphReport = () => {
        let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.get('account-history/dashboard-graph' + queryParams)
            .then(({ data }) => {
                let chartData = JSON.parse(JSON.stringify(isGraphDataChartData));

                chartData.labels = data.dates
                chartData.datasets[0].data = data.totalValues;
                setGraphDataChartData(chartData);
                // console.log('chartdata',chartData);

            }).catch(error => {
            })
    }
    return (
        <div>
            {/* {JSON.stringify(isGraphDataChartData)} */}
            <Line height={220} data={isGraphDataChartData} options={isGraphDatachartOptions} />
        </div>
    );
};

export default LineChart;
