import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import connection, {
    removeConnectionDetails
} from '../../store/apps/connection';
import axiosClient from "../../axios-client";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";

const ConnectionAccounts = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    // url params 
    const params = useParams();
    const { role } = useStateContext();
    const [isErrorMsg, setErrorMsg] = useState('');
    const [isLoadingRequest, setLoadingRequest] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isType, setType] = useState(null);
    const [isAccountData, setAccountData] = useState(null);
    const [selectedAccounts, setSelectedAccounts] = useState([]);
    const [intervalId, setIntervalId] = useState(null);
    const [isCheckAll, setCheckAll] = useState(false);
    const [isErrorLoading, setErrorLoading] = useState(false);
    const dispatch = useDispatch();
    const connections = useSelector((state) => state.connection);
    useEffect(() => {

    }, [connections])
    useEffect(() => {
        if (props_modelHideshow) {
            if (connections.addConnectionDetails && connections.addConnectionDetails.institution) {
                setAccountData(null);
                setCheckAll(false);
                setErrorLoading(false);
                setType(null);
                asyncWithAuth(connections.addConnectionDetails);
            }
        } else {
            setErrorLoading(false);
            setAccountData(null);
            setType(null);
        }
    }, [props_modelHideshow])
    const callingAsyncAccountFunction = (param) => {
        axiosClient.post('connection/async/account', param)
            .then(({ data }) => {
                if (data.status == 400) {
                    setErrorMsg(data.res);
                    stopInterval();
                } else if (data.status == 202) {
                    setErrorMsg(data.res);

                } else {
                    stopInterval()
                }
            }).catch(error => {
                console.log('error occurd')
            })
    }
    const asyncWithAuth = (param) => {
        if (isAccountData != null && isAccountData.institution) {
        } else {
            setLoadingRequest(true)
            let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
            axiosClient.post('connection/auth/account' + queryParams, param)
                .then(({ data }) => {
                    if (data.status == 200) {
                        setAccountData(data.data);
                        setType(data.data.type);
                        setErrorLoading(false);
                    } else {
                        setErrorLoading(true);
                        setLoadingRequest(false);
                    }
                }).catch(error => {
                    setLoadingRequest(false);
                    setErrorLoading(true);
                    console.log('error occurd', error);
                })
        }
    }
    const startInterval = async (data) => {
        setLoadingRequest(true);
        callingAsyncAccountFunction(data)
        const id = setInterval(() => callingAsyncAccountFunction(data), 30000);
        setIntervalId(id);
    };
    const stopInterval = () => {
        clearInterval(intervalId);
        setIntervalId(null);
        setLoadingRequest(false);
    };
    const saveConnectionDetails = () => {
        setLoading(true);
        let postData = JSON.parse(JSON.stringify(connections.addConnectionDetails));
        const selectedAccountObjects = selectedAccounts.map(accountId =>
            isType == null || isType == 'banking' ?
                isAccountData.accounts.find(account => account.accountId === accountId)
                : isAccountData.accounts.find(account => account.FullAccountId === accountId)
        );
        postData.accounts = selectedAccountObjects;
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('connection/save' + queryParams, postData)
            .then(({ data }) => {
                setLoading(false);
                setAccountData(null);
                setSelectedAccounts([]);
                toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                dispatch(removeConnectionDetails());
                props_callModelCloseFunction();
            }).catch(error => {
                setLoading(false);
                console.log('error occurd')
            })
    }
    const handleCheckboxChange = (value) => {
        if (selectedAccounts.includes(value)) {
            setSelectedAccounts(selectedAccounts.filter(item => item !== value));
        } else {
            setSelectedAccounts([...selectedAccounts, value]);
        }
    };
    const handleAllCheckboxChange = (e) => {
        const { checked } = e.target;
        setCheckAll(checked);
        if (checked) {
            // If "check all" was checked, select all non-disabled accounts
            const accountIds = isAccountData.accounts
                .filter((account) => !account.disabledAccount)
                .map((account) => (isType === 'banking' ? account.accountId : account.FullAccountId));
            setSelectedAccounts(accountIds);
        } else {
            // If "check all" was unchecked, clear the selected accounts
            setSelectedAccounts([]);
        }
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Accounts</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {
                            isType == null
                                ?
                                'Your connection is successful. We are now fetching your accounts.'
                                :
                                'We have fetched following accounts through your connection. Please choose, which accounts, do you want to save.'
                        }

                    </span>
                    <>
                        {
                            isAccountData != null && isAccountData.accounts && isAccountData.accounts.length > 0
                                ?
                                <>
                                    <div className="" id="table_open">
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col" className="text-center">

                                                        {isAccountData.accounts.some(account => !account.disabledAccount) && (
                                                            <input
                                                                type="checkbox"
                                                                id={'account-checkall'}
                                                                name="account"
                                                                value={isCheckAll}
                                                                onChange={event => handleAllCheckboxChange(event)}
                                                                checked={isCheckAll}
                                                            />
                                                        )}
                                                    </th>
                                                    <th scope="col" className="text-center">Account No.</th>
                                                    <th scope="col" className="text-center">Name</th>
                                                    <th scope="col" className="text-center">{
                                                        isType == null || isType == 'banking' ? 'Balance' : 'Cash'}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isAccountData.accounts.map((account, ind) => (
                                                    <tr key={ind}>
                                                        <td className="text-center">
                                                            {
                                                                isType == null || isType == 'banking' ?
                                                                    <>
                                                                        {account.disabledAccount

                                                                            ?
                                                                            'Already added'
                                                                            :
                                                                            <input
                                                                                type="checkbox"
                                                                                id={'account-' + ind}
                                                                                name="account"
                                                                                value={account.accountId}
                                                                                onChange={event => handleCheckboxChange(account.accountId)}
                                                                                checked={selectedAccounts.includes(account.accountId)}
                                                                                disabled={account.disabledAccount}
                                                                            />
                                                                        }

                                                                    </>
                                                                    :
                                                                    <>
                                                                        {account.disabledAccount

                                                                            ?
                                                                            'Already added'
                                                                            :
                                                                            <input
                                                                                type="checkbox"
                                                                                id={'account-' + ind}
                                                                                name="account"
                                                                                value={account.FullAccountId}
                                                                                onChange={event => handleCheckboxChange(account.FullAccountId)}
                                                                                checked={selectedAccounts.includes(account.FullAccountId)}
                                                                                disabled={account.disabledAccount}
                                                                            />
                                                                        }

                                                                    </>
                                                            }

                                                        </td>
                                                        <td>
                                                            {
                                                                isType == null || isType == 'banking'
                                                                    ?
                                                                    <>
                                                                        {account && account.accountNo
                                                                            ? account.accountNo
                                                                            :
                                                                            'N/A'}
                                                                    </>

                                                                    :
                                                                    <>
                                                                        {account && account.AccountId
                                                                            ? account.AccountId
                                                                            :
                                                                            'N/A'}
                                                                    </>
                                                            }

                                                        </td>
                                                        <td>
                                                            {
                                                                isType == null || isType == 'banking'
                                                                    ?
                                                                    <>
                                                                        {account && account.name
                                                                            ? account.name
                                                                            :
                                                                            'N/A'}
                                                                    </>

                                                                    :
                                                                    <>
                                                                        {account && account.Name
                                                                            ? account.Name
                                                                            :
                                                                            'N/A'}
                                                                    </>
                                                            }</td>
                                                        <td>
                                                            {
                                                                isType == null || isType == 'banking'
                                                                    ?
                                                                    <>
                                                                        {account && account.availableBalance
                                                                            ? account.availableBalance
                                                                            :
                                                                            'N/A'}
                                                                    </>

                                                                    :
                                                                    <>
                                                                        {account && account.AccountValue
                                                                            ? account.AccountValue
                                                                            :
                                                                            'N/A'}
                                                                    </>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                :
                                <>
                                    {isAccountData && isAccountData.accountStatus == 0 ? (
                                        <div className="" id="table_open">
                                            <table className="table ">
                                                <thead>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Account No.</th>
                                                        <th scope="col">Name</th>
                                                        <th scope="col">{
                                                            isType == null && isType == 'banking' ? 'Balance' : 'Cash'}</th>
                                                    </tr>
                                                </thead>
                                                <tbody >
                                                    <tr style={{ position: 'relative' }}>
                                                        <td colSpan={4}>
                                                            <div className="card loadingCard loadingCardOverlap inlineLoading">
                                                                <div className="card-body">
                                                                    <>
                                                                        <div className="componentLoading bg-white text-center">
                                                                            {
                                                                                isLoadingRequest

                                                                                    ?
                                                                                    <div className="spinner-border m-1" role="status"></div>

                                                                                    :
                                                                                    null
                                                                            }
                                                                            <div className='mt-1'>
                                                                                {
                                                                                    isAccountData.request_response && isAccountData.request_response != null &&
                                                                                        isAccountData.request_response.HttpStatus == 400

                                                                                        ?
                                                                                        <>
                                                                                            <div className="create_entity create_entity_custom" onClick={() => asyncWithAuth(isAccountData)}>
                                                                                                <span > {
                                                                                                    isLoadingRequest

                                                                                                        ?
                                                                                                        'Please wait...'
                                                                                                        :
                                                                                                        'Try Again'
                                                                                                }</span>
                                                                                            </div>
                                                                                            <div>{isErrorMsg}</div>
                                                                                        </>
                                                                                        :

                                                                                        <>
                                                                                            <div className="create_entity create_entity_custom" onClick={() => startInterval(isAccountData)}>
                                                                                                <span >Send Async request</span>
                                                                                            </div>
                                                                                            <div>{isErrorMsg}</div>
                                                                                        </>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </>
                                                                </div>
                                                            </div>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    ) : <>
                                        <div className="card loadingCard loadingCardOverlap inlineLoading">
                                            <div className="card-body">
                                                <div className="componentLoading bg-white text-center">

                                                    {
                                                        isLoadingRequest && isErrorLoading == false

                                                            ?
                                                            <div className="spinner-border m-1" role="status"></div>

                                                            :
                                                            <>

                                                                {
                                                                    isErrorLoading ?
                                                                        <>
                                                                            {
                                                                                isLoadingRequest

                                                                                    ?
                                                                                    <div className="spinner-border m-1" role="status"></div>
                                                                                    :
                                                                                    <div>We could not fetch your accounts.</div>
                                                                            }

                                                                            <div className="create_entity create_entity_custom" onClick={() => asyncWithAuth(connections.addConnectionDetails)}>
                                                                                <span > {
                                                                                    isLoadingRequest
                                                                                        ?
                                                                                        'Please wait...'
                                                                                        :
                                                                                        'Try Again'
                                                                                }</span>
                                                                            </div>
                                                                            <div>{isErrorMsg}</div></>
                                                                        :

                                                                        null
                                                                }
                                                            </>
                                                    }
                                                </div>
                                            </div></div>
                                    </>}

                                </>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={loading || selectedAccounts.length === 0}
                        onClick={saveConnectionDetails}
                    >
                        {loading ? 'Loading...' : 'Submit'}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ConnectionAccounts;