// ** Redux Imports
import { Dispatch } from 'redux'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

// ** Token
const accessToken = localStorage.getItem("ACCESS_TOKEN");


// ** fetch connection  **
export const getConnections = createAsyncThunk('getConnections', async (data) => {
    let queryParams = data != undefined ? '?userId=' + data : '';
    const response = await axios.get(process.env.REACT_APP_API_URL + "/connections" + queryParams, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})

export const getUserData = createAsyncThunk('getUserData', async (data) => {
    let queryParams = data != undefined ? '?userId=' + data : '';
    const response = await axios.get(process.env.REACT_APP_API_URL + "/user-setting"+queryParams, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})

export const getEntityList = createAsyncThunk('getEntityList', async (data) => {
    let queryParams = data != undefined ? '?userId=' + data : '';
    const response = await axios.get(process.env.REACT_APP_API_URL + "/entities" + queryParams, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})
export const getManualInputList = createAsyncThunk('getManualInputList', async (data) => {
    let queryParams = data != undefined ? '?userId=' + data : '';
    const response = await axios.get(process.env.REACT_APP_API_URL + "/manual-input" + queryParams, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})
export const getDashboardData = createAsyncThunk('getDashboardData', async (data) => {
    let queryParams = data != undefined ? '?userId=' + data : '';
    const response = await axios.get(process.env.REACT_APP_API_URL + "/dashboard/assets-libality/report" + queryParams, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})
export const addConnectionDetails = createAsyncThunk('addConnectionDetails', async (data) => {
    return data;
})

export const getConnectionAccounts = createAsyncThunk('getConnectionAccounts', async (data) => {
    const response = await axios.post(process.env.REACT_APP_API_URL + "/connection/save", data, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})
export const saveConnectionWithAccounts = createAsyncThunk('saveConnectionWithAccounts', async (data) => {
    const response = await axios.post(process.env.REACT_APP_API_URL + "/connection/save", data, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data
})
export const removeConnectionDetails = createAsyncThunk('removeConnectionDetails', async (data) => {
    console.log('remove');
    return []
})
// Fetch Admin Data
export const getAdminsData = createAsyncThunk('getAdminsData', async (data) => {

    const response = await axios.get(process.env.REACT_APP_API_URL + "/users/admins", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data;
})
// Fetch Advisor Data
export const getAdvisorsData = createAsyncThunk('getAdvisorsData', async (data) => {

    const response = await axios.get(process.env.REACT_APP_API_URL + "/users/advisors", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data;
})
// Fetch Clients Data
export const getUsersData = createAsyncThunk('getUsersData', async (data) => {

    const response = await axios.get(process.env.REACT_APP_API_URL + "/users", {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data;
})
export const clientDataForDashboard = createAsyncThunk('clientDataForDashboard', async (id) => {

    const response = await axios.get(process.env.REACT_APP_API_URL + "/user/detail/" + id, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
    return response.data;
})
export const appConnectionSlice = createSlice({
    name: 'connection',
    initialState: {
        getConnections: [],
        addConnectionDetails: {},
        saveConnectionWithAccounts: {},
        getConnectionAccounts: [],
        getEntityList: [],
        getManualInputList: [],
        getDashboardData: {},
        getUserData: {},
        getAdminsData: [],
        getAdvisorsData: [],
        getUsersData: [],
        clientDataForDashboard: null

    },
    reducers: {},
    extraReducers: builder => {
        builder.addCase(clientDataForDashboard.fulfilled, (state, action) => {
            state.clientDataForDashboard = action.payload;
        })
        builder.addCase(getUsersData.fulfilled, (state, action) => {
            state.getUsersData = action.payload;
        })
        builder.addCase(getAdvisorsData.fulfilled, (state, action) => {
            state.getAdvisorsData = action.payload;
        })
        builder.addCase(getAdminsData.fulfilled, (state, action) => {
            state.getAdminsData = action.payload;
        })
        builder.addCase(getConnections.fulfilled, (state, action) => {
            state.getConnections = action.payload;
        })
        builder.addCase(getUserData.fulfilled, (state, action) => {
            state.getUserData = action.payload;
        })
        builder.addCase(getEntityList.fulfilled, (state, action) => {
            state.getEntityList = action.payload;
        })
        builder.addCase(getManualInputList.fulfilled, (state, action) => {
            state.getManualInputList = action.payload;
        })
        builder.addCase(getDashboardData.fulfilled, (state, action) => {
            state.getDashboardData = action.payload;
        })
        builder.addCase(addConnectionDetails.fulfilled, (state, action) => {
            state.addConnectionDetails = action.payload;
        })
        builder.addCase(saveConnectionWithAccounts.fulfilled, (state, action) => {
            state.saveConnectionWithAccounts = action.payload;
        })
        builder.addCase(getConnectionAccounts.fulfilled, (state, action) => {
            state.getConnectionAccounts = action.payload;
        })
        builder.addCase(removeConnectionDetails.fulfilled, (state, action) => {
            state.addConnectionDetails = {};
        })
    }
})

export default appConnectionSlice.reducer
