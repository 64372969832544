import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useNavigate } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
const Index = () => {
  const navigate = useNavigate();
  const { permissions, role } = useStateContext();
  useEffect(() => {
    if (permissions != undefined) {
      if (_.includes(permissions, 'view_dashboard')) {
        if (role == 'advisor') {
          navigate('/clients')
        } else {
          navigate('/dashboard')
        }
      } else {
        navigate('page-404');
      }
    }
  }, [permissions])
  return (
    <>
    </>
  )
}

export default Index