import React, { useEffect, useState } from "react";
import CreateManualInputModel from "../Components/Modals/CreateManualInputModel";
import { useDispatch, useSelector } from 'react-redux'
import {
    getConnections,
    getManualInputList
} from '../store/apps/connection'
import { ComponentLoading } from "../Components/ComponentLoading";
import ManualInputTable from "../Components/Tables/ManualInputTable";
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";

import _ from 'lodash';
const ManualInput = () => {
    // url params 
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { permissions, role } = useStateContext();
    const [isShowCreateManualInputModel, setShowCreateManualInputModel] = useState(false);
    const [isManualInputData, setManualInputData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        setIsLoading(true)

    }, [dispatch])
    useEffect(() => {
        if (connections.getManualInputList && connections.getManualInputList.data) {
            setManualInputData(connections.getManualInputList.data);
        }
    }, [connections])
    useEffect(() => {
        if (permissions != undefined) {
            if (_.includes(permissions, 'view_manual_inputs')) {
                if (params && params.userId && role == 'advisor') {
                    dispatch(getManualInputList(params.userId));
                    dispatch(getConnections(params.userId));
                } else if (role == 'advisor') {
                    navigate('/page-404');
                } else if (role != null) {
                    // dispatch(getEntityList());
                    dispatch(getManualInputList());
                    dispatch(getConnections());
                }
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    const addManualInputFunc = () => {
        setShowCreateManualInputModel(true);
    };
    const handleShowManualInputModalClose = async () => {
        setShowCreateManualInputModel(false);
        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
        dispatch(getManualInputList(queryParams));
        dispatch(getConnections(queryParams));
    };
    return (
        <>

            <div className="sub_main_entity">
                <div className="entity_heading">
                    <div>
                        <h4>
                            Manual Inputs
                        </h4>
                    </div>
                    <div>
                        {
                            permissions != undefined && _.includes(permissions, 'manage_manual_inputs')
                                ?
                                <div className="create_manual_input">
                                    <a href="#"
                                        onClick={() => addManualInputFunc()}
                                    >Create Manual Input</a>
                                </div>
                                :
                                null
                        }
                    </div>

                </div>
                {isLoading ?
                    <>
                        {
                            isManualInputData != null && isManualInputData.length > 0
                                ?
                                <ManualInputTable data={isManualInputData} />
                                :
                                <div className="entity_table mt-5">
                                    <table className="table table3">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="text-center">No.</th>
                                                <th scope="col" className="text-center">Name</th>
                                                <th scope="col" className="text-center">Assets/Liability</th>
                                                <th scope="col" className="text-center">Balance</th>
                                                <th scope="col" className="text-center">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody><tr style={{ position: 'relative' }}>
                                            <td colSpan={6}>
                                                <div className="card loadingCard loadingCardOverlap inlineLoading">
                                                    <div className="card-body">
                                                        <div className="componentLoading bg-white text-center">
                                                            <div>No data found</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        </tbody>
                                    </table>
                                </div>
                        }
                    </>
                    :
                    <div className="card loadingCard">
                        <div className="card-body">
                            <ComponentLoading></ComponentLoading>
                        </div>
                    </div>
                }


            </div>
            <CreateManualInputModel props_modelHideshow={isShowCreateManualInputModel} props_callModelCloseFunction={handleShowManualInputModalClose} />
        </>
    )
}

export default ManualInput;