import React, { useContext, useEffect, useState } from "react";
import { useNavigate ,useParams} from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider"
import house from '../images/insights_two.png';
import _ from 'lodash';
const Insights = () => {
    const { user, role, notification } = useStateContext();
    const navigate = useNavigate();
     // url params 
     const params = useParams();
    const { permissions } = useStateContext();
    useEffect(() => {
        if (permissions != undefined) {
            if (_.includes(permissions, 'view_insights')) {
                if (params && params.userId && role == 'advisor') {
                } else if (role == 'advisor') {
                    navigate('/page-404');
                } 
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    return (
        <>
            <div class="sub_main_insight">
                <div class="sub_main_insight_header">
                    <div class="sub_main_insight_text">
                        <h1>
                            insights
                        </h1>
                        <p>
                            LVR = Loan to Value Ratio
                        </p>
                        <h6>
                            How much am I borrowing as a proportion of the value of my house
                        </h6>
                    </div>
                    <div>
                        <button>
                            Family Banking Summary
                        </button>
                    </div>
                </div>
                <div class="sub_main_insight_banking">

                    <div class="banking-img banking-img-1">
                        <img src={house} alt="img" />
                        <span>
                            <h2>
                                60%
                            </h2>
                            <p>
                                1670 Fort St
                            </p>
                        </span>
                    </div>
                    <div class="banking-img banking-img-2">
                        <img src={house} alt="img" />
                        <span>
                            <h2>
                                80%
                            </h2>
                            <p>
                                412 Clover Dr
                            </p>
                        </span>
                    </div>
                    <div class="banking-img banking-img-3">
                        <img src={house} alt="img" />
                        <span>
                            <h2>
                                100%
                            </h2>
                            <p>
                                5 Diane St
                            </p>
                        </span>
                    </div>
                </div>
                <div class="sub_main_insight_account">
                    <div class="insight_account_wrapper pt-3">
                        <div class="insight_account">
                            <p>
                                Property Value
                            </p>
                            <h6>
                                $500,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Property Value
                            </p>
                            <h6>
                                $500,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Property Value
                            </p>
                            <h6>
                                $500,000
                            </h6>
                        </div>

                    </div>
                    <div class="insight_account_wrapper">
                        <div class="insight_account">
                            <p>
                                Mortage
                            </p>
                            <h6>
                                $300,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Mortage
                            </p>
                            <h6>
                                $400,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Mortage
                            </p>
                            <h6>
                                $500,000
                            </h6>
                        </div>
                    </div>
                    <div class="insight_account_wrapper">
                        <div class="insight_account">
                            <p>
                                Equity
                            </p>
                            <h6>
                                $200,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Equity
                            </p>
                            <h6>
                                $100,000
                            </h6>
                        </div>
                        <div class="insight_account">
                            <p>
                                Equity
                            </p>
                            <h6>
                                $0
                            </h6>
                        </div>
                    </div>
                    <div class="insight_account_text">
                        <p>
                            If your <span>LVR</span> = 80%, you are borrowing 80% of the value of your house

                        </p>
                    </div>
                    <div class="insight_account_value">
                        <div class="insight_account_value_1">
                            <h1>
                                Calculation
                            </h1>
                            <p>
                                Amount you are borrowing
                            </p>
                            <h6>
                                Value of your house
                            </h6>
                        </div>
                        <div class="insight_account_value_1 insight_account_value_2">
                            <div>
                                <h1>
                                    412 Clover Dr
                                </h1>
                                <p>
                                    $&nbsp;400,000
                                </p>
                                <h6>
                                    $&nbsp;500,000

                                </h6>
                            </div>
                            <div class="account_value">
                                <h6>

                                    x 100 = 80%

                                </h6>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Insights;