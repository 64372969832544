import { createContext, useContext, useState } from "react";

const StateContext = createContext({
    user: null,
    role: null,
    token: null,
    setUser: () => { },
    setRole: () => { },
    setToken: () => { },
    setPermissions: () => []
})

export const ContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const [role, setRole] = useState();
    const [permissions, setPermissions] = useState();

    const [token, _setToken] = useState(localStorage.getItem('ACCESS_TOKEN'));


    const setToken = (token) => {
        _setToken(token)
        if (token) {
            localStorage.setItem('ACCESS_TOKEN', token);
        } else {
            localStorage.removeItem('ACCESS_TOKEN')
        }
    }
    return (
        <StateContext.Provider value={{
            user,
            role,
            token,
            permissions,
            setUser,
            setRole,
            setPermissions,
            setToken
        }}>
            {children}
        </StateContext.Provider>
    )
}

export const useStateContext = () => useContext(StateContext)