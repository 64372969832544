import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js/auto';

const ChartComponent = ({ type, data, options }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = new Chart(chartRef.current, {
      type: type,
      data: data,
      options: options,
    });

    return () => {
      myChart.destroy();
    };
  }, [type, data, options]);
  
  return <canvas ref={chartRef} />;
};

export default ChartComponent;
