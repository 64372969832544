import React, { useContext, useEffect, useState, useRef } from "react";
import { ComponentLoading } from "../Components/ComponentLoading";
import connection, {
    getConnections,
    removeConnectionDetails
} from '../store/apps/connection'
import { useDispatch, useSelector } from 'react-redux'
import AddConnectionModal from "../Components/Modals/AddConnectionModel";
import ConnectionAccounts from "../Components/Modals/GetConnectionsAccounts"
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import axiosClient from "../axios-client";
import * as Icon from "react-feather"
import CreateEnitityModel from "../Components/Modals/CreateEntityModel";
import CreateLinkToEntity from "../Components/Modals/CreateLinkToEntity";
import { OverlayTrigger, Tooltip, Dropdown } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
import AccountHistoryModel from "../Components/Modals/AccountHistoryModel";
import TroubleshootModel from "../Components/Modals/TroubleshootModel";
const Connections = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // url params 
    const params = useParams();
    const { permissions, role } = useStateContext();
    const [isLoading, setIsLoading] = useState(false);
    const [isConnections, setConnetions] = useState([]);
    const [isShowConnectionModel, setShowConnectionModel] = useState(false);
    const [isShowConnetAccountsModel, setShowConnetAccountsModel] = useState(false);
    const [isShowCreateEntityModel, setShowCreateEntityModel] = useState(false);
    const [isShowCreateLinkToEntityModel, setShowCreateLinkToEntityModel] = useState(false);
    const [isInvestmenAndEnitity, setInvestmenAndEnitity] = useState({});
    const [isReconnectConnectionProps, setReconnectConnectionProps] = useState(null);
    const [isAccountHistoryModel, setAccountHistoryModel] = useState(false);
    const [isAcccountHistoryProps, setAcccountHistoryProps] = useState({});
    const [isHideShowTroubleShootModel, setHideShowTroubleShootModel] = useState(false);
    const [isConnectionId, setConnectionId] = useState(null);
    const [isCollapse, setCollapse] = useState({
        ind: 0,
        status: false
    });
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        setIsLoading(true)
    }, [dispatch])
    useEffect(() => {
        if (connections.getConnections && connections.getConnections.connections) {
            setConnetions(connections.getConnections.connections);
        }
    }, [connections])
    useEffect(() => {
        if (permissions != undefined) {
            if (_.includes(permissions, 'view_connections')) {
                if (params && params.userId && role == 'advisor') {
                    dispatch(getConnections(params.userId));
                } else if (role == 'advisor') {
                    navigate('/page-404');
                } else if (role != null) {
                    dispatch(getConnections());
                }
            } else {
                navigate('/page-404');
            }
        }
    }, [permissions])
    const addEntityFunc = () => {
        setShowCreateEntityModel(true);
    }
    const handleCeateLinkToEntityModalClose = async () => {
        setShowCreateLinkToEntityModel(false);
    };
    const CeateLinkToEntityModalOpen = async (connectionId, accountId, type, unlinkedStatus, account) => {
        let data = {
            connectionId: connectionId,
            accountId: accountId,
            connectionType: type,
            linkData: account.link_account
        };
        setInvestmenAndEnitity(data);
        setShowCreateLinkToEntityModel(true);
    };
    const handleShowEntityModalClose = async () => {
        setShowCreateEntityModel(false);
        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
        dispatch(getConnections(queryParams));
    };
    const addConnectionFunc = (institutionId, institutiontype) => {
        //condition check institutionId & type is not equal to null 
        if (institutionId != null && institutiontype != null) {
            let institute = { instituteId: institutionId, type: institutiontype };
            setReconnectConnectionProps(institute);
        }
        // Open add connection model
        setShowConnectionModel(true);
    }
    const handleShowConnectionModalClose = async () => {
        setShowConnectionModel(false);
    };
    const handleAutoCloseConnectionModalClose = () => {
        setShowConnectionModel(false);
        setShowConnetAccountsModel(true);
    }
    const handleShowConnectAccountsModalClose = async () => {
        setShowConnetAccountsModel(false);
        dispatch(removeConnectionDetails());
        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
        dispatch(getConnections(queryParams));
    };
    const deleteBtn = async (data) => {
        Swal.fire({
            title: "Are you sure want to delete?",
            icon: "question",
            confirmButtonColor: "#f32013",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    id: data.id,
                };
                let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.post("connection/delete" + queryParams, payload).then((response) => {
                    if (response.data.status == 200) {
                        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
                        dispatch(getConnections(queryParams));
                        toast.success(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    } else {
                        toast.error(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    }
                });
            } else {
            }
        });
    };
    const showAccountHistory = (connectionId, account) => {
        let accountDetails = {
            connectionId: connectionId,
            accountId: account.accountId ? account.accountId : account.FullAccountId
        }
        setAcccountHistoryProps(accountDetails);
        setAccountHistoryModel(true);
    }
    const handleAccountHistoryModalClose = () => {
        setAccountHistoryModel(false);
    }
    const troubleShootModel = (connectionId) => {
        setConnectionId(connectionId);
        setHideShowTroubleShootModel(true);
    }
    const handleTroubleShootModelModalClose = () => {
        setConnectionId(null);
        setHideShowTroubleShootModel(false);
        let queryParams = params && params.userId && role == 'advisor' ?  params.userId : '';
        dispatch(getConnections(queryParams));
    }
    return (
        <>
            <div className="page-content">
                {isLoading ?
                    <>
                        <div className="d-flex justify-content-between">
                            <div className="pt-3 pl-3 pr-3 pb-3">
                                <div className="card-toolbar">
                                    <div
                                        className="d-flex justify-content-center m-auto"
                                        data-kt-customer-table-toolbar="base"
                                    >
                                    </div>
                                </div>
                            </div>
                            <div className="pl-3 pr-3 pb-3">
                                <div className="entity_btn">
                                    {
                                        permissions != undefined && _.includes(permissions, 'manage_entities')
                                            ?
                                            <div className="create_entity">
                                                <a href="#" onClick={() => addEntityFunc()} >Create an Entity</a>
                                            </div>
                                            :
                                            null
                                    }
                                    {
                                        permissions != undefined && _.includes(permissions, 'manage_connections')
                                            ?
                                            <div className="create_entity create_entity_fill">
                                                <a href="#" onClick={() => addConnectionFunc()}>Add Connections</a>
                                            </div>
                                            :
                                            null
                                    }
                                </div>
                            </div>
                        </div>

                        {
                            isConnections.length > 0
                                ?
                                <>
                                    {isConnections.map((connection, index) => (
                                        <div className="all_bank_data" key={index}>
                                            <div className="bank_img">
                                                <div className="bank_logo_custom">
                                                    <div className="logo_width">
                                                        <OverlayTrigger
                                                            trigger={['hover', 'focus']}
                                                            placement="top"
                                                            overlay={<Tooltip id="example-tooltip">
                                                                {connection.institution}
                                                            </Tooltip>}
                                                        >
                                                            {
                                                                connection.logo != null
                                                                    ?

                                                                    <div className="bank_icon">
                                                                        <img width="30%" src={connection.logo} alt={connection.institution} />
                                                                    </div>
                                                                    :
                                                                    <p className="mt-3 fw-semibold">{connection.institution}</p>

                                                            }
                                                        </OverlayTrigger>


                                                    </div>
                                                    <div className="mt-3 last_seen">
                                                        <div>

                                                            {
                                                                connection.connectionStatus

                                                                    ?
                                                                    <div className="active_btn">
                                                                        active
                                                                    </div>
                                                                    :
                                                                    <div className="reconnected_btn" onClick={() => addConnectionFunc(connection.institutionId, connection.accounts.length > 0 ? 'banking' : 'investment')} >
                                                                        Reconnect
                                                                    </div>

                                                            }

                                                            <div>
                                                                <p style={{ color: '#364B7F' }} className="mt-1 fw-semibold">
                                                                    Last updated:  {connection.updatedTime + ' ago'}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="icons icons_first">
                                                    {
                                                        permissions != undefined && _.includes(permissions, 'manage_connections')
                                                            ?
                                                            <div className="Btn_dropdown">
                                                                <Dropdown className="custom-dropdown" >
                                                                    <Dropdown.Toggle id="dropdown-basic">
                                                                        <i className="fa fa-ellipsis-vertical" ></i>
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item href="#" onClick={() => {
                                                                            deleteBtn(connection);
                                                                        }}>Delete</Dropdown.Item>
                                                                        <Dropdown.Item href="#" onClick={() => {
                                                                            troubleShootModel(connection.id);
                                                                        }}>Troubleshoot Flinks</Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                    <div>
                                                        <a href="#"
                                                            id="show_table" onClick={() => {
                                                                let value = {};
                                                                if (isCollapse.ind === index) {
                                                                    value = {
                                                                        ind: index,
                                                                        status: !isCollapse.status
                                                                    }
                                                                    setCollapse(value);
                                                                } else {
                                                                    value = {
                                                                        ind: index,
                                                                        status: true
                                                                    }
                                                                    setCollapse(value);
                                                                }

                                                            }} >
                                                            <div className="select ">
                                                                {
                                                                    isCollapse && isCollapse.status === true && isCollapse.ind === index

                                                                        ?
                                                                        <i className="fa fa-caret-right" id="rotate"></i>
                                                                        :
                                                                        <i className="fa fa-caret-right rotate" id="rotate"></i>
                                                                }

                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                isCollapse && isCollapse.status === true && isCollapse.ind === index
                                                    ?
                                                    <>
                                                        {
                                                            connection.accounts && connection.accounts.length > 0

                                                                ?
                                                                <>
                                                                    <div className="" id="table_open">
                                                                        <table className="table ">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col" className="text-center">Account No.</th>
                                                                                    <th scope="col" className="text-center">Name</th>
                                                                                    <th scope="col" className="text-center">Assets/Liability</th>
                                                                                    <th scope="col" className="text-center">Balance</th>
                                                                                    <th scope="col" className="text-center">Type</th>
                                                                                    <th scope="col" className="text-center"></th>
                                                                                    <th scope="col" className="text-center">History</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {connection.accounts.map((account, ind) => (
                                                                                    <tr key={ind}>
                                                                                        <td>{
                                                                                            account && account.accountNo
                                                                                                ?
                                                                                                account.accountNo
                                                                                                :
                                                                                                'N/A'
                                                                                        }</td>
                                                                                        <td>{account.name}</td>
                                                                                        <td>{
                                                                                            account.link_account && account.link_account.type

                                                                                                ?
                                                                                                account.link_account.type
                                                                                                :
                                                                                                'N/A'
                                                                                        }</td>
                                                                                        <td>{account.availableBalance != null
                                                                                            ?
                                                                                            account.availableBalance
                                                                                            :
                                                                                            'N/A'
                                                                                        }</td >
                                                                                        <td>{
                                                                                            account.link_account && account.link_account.account
                                                                                                ?
                                                                                                account.link_account.account
                                                                                                :
                                                                                                'N/A'
                                                                                        }</td>
                                                                                        <td className="link">
                                                                                            {
                                                                                                permissions != undefined && _.includes(permissions, 'manage_connections')
                                                                                                    ?
                                                                                                    <span href="# " style={{ backgroundColor: account.status == 1 ? '' : '#ECA7FF' }}
                                                                                                        onClick={() => CeateLinkToEntityModalOpen(connection.id, account.id, 'banking', account.status, account)}

                                                                                                    >{
                                                                                                            account.status == 1 ?
                                                                                                                'linked'
                                                                                                                :
                                                                                                                'unlinked'
                                                                                                        }</span>
                                                                                                    :
                                                                                                    null
                                                                                            }

                                                                                        </td>
                                                                                        <td className="text-center">
                                                                                            <Icon.Clock onClick={() => showAccountHistory(connection.id, account)} />
                                                                                        </td>
                                                                                    </tr>
                                                                                ))}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </>
                                                                :
                                                                <>
                                                                    {
                                                                        connection.investment && connection.investment.length > 0


                                                                            ?
                                                                            <div className="" id="table_open">
                                                                                <table className="table ">
                                                                                    <thead>
                                                                                        <tr>
                                                                                            <th scope="col" className="text-center">Account No.</th>
                                                                                            <th scope="col" className="text-center">Name</th>
                                                                                            <th scope="col" className="text-center">Assets/Liability</th>
                                                                                            <th scope="col" className="text-center">Cash</th>
                                                                                            <th scope="col" className="text-center">Type</th>
                                                                                            <th scope="col" className="text-center"></th>
                                                                                            <th scope="col" className="text-center">History</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {connection.investment.map((account, ind) => (
                                                                                            <tr key={ind}>
                                                                                                <td>{
                                                                                                    account && account.AccountId
                                                                                                        ?
                                                                                                        account.AccountId
                                                                                                        :
                                                                                                        'N/A'
                                                                                                }</td>
                                                                                                <td>{account.Name}</td>
                                                                                                <td>{
                                                                                                    account.link_account && account.link_account.type

                                                                                                        ?
                                                                                                        account.link_account.type
                                                                                                        :
                                                                                                        'N/A'
                                                                                                }</td>
                                                                                                <td>{account.AccountValue != null
                                                                                                    ?
                                                                                                    account.AccountValue
                                                                                                    :
                                                                                                    'N/A'
                                                                                                }</td >
                                                                                                <td>{
                                                                                                    account.link_account && account.link_account.account
                                                                                                        ?
                                                                                                        account.link_account.account
                                                                                                        :
                                                                                                        'N/A'
                                                                                                }</td>
                                                                                                <td className="link">
                                                                                                    {
                                                                                                        permissions != undefined && _.includes(permissions, 'manage_connections')
                                                                                                            ?
                                                                                                            <span href="# " style={{ backgroundColor: account.status == 1 ? '' : '#ECA7FF' }}

                                                                                                                onClick={() => CeateLinkToEntityModalOpen(connection.id, account.id, 'investment', account.status, account)}
                                                                                                            >{
                                                                                                                    account.status == 1 ?
                                                                                                                        'linked'
                                                                                                                        :
                                                                                                                        'unlinked'
                                                                                                                }</span>
                                                                                                            :
                                                                                                            null
                                                                                                    }

                                                                                                </td>
                                                                                                <td className="text-center">
                                                                                                    <Icon.Clock onClick={() => showAccountHistory(connection.id, account)} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                </>
                                                        }
                                                    </>
                                                    :
                                                    null
                                            }
                                        </div>
                                    ))}
                                </>
                                :
                                // When no connections found
                                <div className="mt-5">
                                    <h1 className="fancy-heading">
                                        Connections
                                    </h1>
                                    <p className="fancy-pragh">No connections found. Click add connections to add another connection.</p>
                                </div>
                        }

                    </>
                    :
                    <div className="card loadingCard">
                        <div className="card-body">
                            <ComponentLoading></ComponentLoading>
                        </div>
                    </div>}

            </div >
            <AddConnectionModal props_modelHideshow={isShowConnectionModel} props_callModelCloseFunction={handleShowConnectionModalClose}
                props_autoCloseConnectionModel={handleAutoCloseConnectionModalClose} props_ReconnectConnection={isReconnectConnectionProps}
            />
            <ConnectionAccounts props_modelHideshow={isShowConnetAccountsModel} props_callModelCloseFunction={handleShowConnectAccountsModalClose} />
            <CreateEnitityModel props_modelHideshow={isShowCreateEntityModel} props_callModelCloseFunction={handleShowEntityModalClose} />
            <CreateLinkToEntity props_modelHideshow={isShowCreateLinkToEntityModel} props_callModelCloseFunction={handleCeateLinkToEntityModalClose} props_ConnectionLinkBankingAndInvestment={isInvestmenAndEnitity} />
            <AccountHistoryModel props_modelHideshow={isAccountHistoryModel} props_callModelCloseFunction={handleAccountHistoryModalClose} props_ConnectionAccountId={isAcccountHistoryProps} />

            {/* TroubleshootModel */}
            <TroubleshootModel props_modelHideshow={isHideShowTroubleShootModel} props_callModelCloseFunction={handleTroubleShootModelModalClose} props_ConnectionId={isConnectionId} />
        </>


    )
}

export default Connections;