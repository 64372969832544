// 
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
    getConnections,
} from '../../store/apps/connection'
import { useDispatch, useSelector } from 'react-redux'
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
const CreateLinkToEntity = ({ props_modelHideshow, props_callModelCloseFunction, props_ConnectionLinkBankingAndInvestment }) => {
    const dispatch = useDispatch();
    // url params 
    const params = useParams();
    const { role } = useStateContext();
    const [isName, setName] = useState(null);
    const [isLinkedLiabilites, setLinkedLiabilites] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectAccount, setSelectedAccount] = useState('');
    const [selectedAssetsAndLiability, setAssetsAndLiability] = useState('Assets');
    const [selectedLinkedAndUnlinked, setLinkedAndUnlinked] = useState('linked');
    const [selectedAssetsAndLiabilityType, setAssetsAndLiabilityType] = useState('');
    const [selectedEntity, setSelectedEntity] = useState('');
    // const [balance, setBalance] = useState(0);
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isOwned, setOwned] = useState(100);
    const [isAvaibleCash, setAvaibleCash] = useState('no');
    const [isNotes, setNotes] = useState('');
    const [getEntity, setEntity] = useState([]);
    const handleAssetsAndLiabilityChange = (e) => {   setSelectedOptions([]);setAvaibleCash('no'); setAssetsAndLiabilityType(''); setAssetsAndLiability(e.target.value); }
    const handleAccountChange = (e) => { setSelectedAccount(e.target.value); };
    const handleAssetsAndLiabilitypeChange = (e) => {
        if (e.target.value == 'Cash') {
            setAvaibleCash('yes');
        } else {
            setAvaibleCash('no');
        }
        setSelectedOptions([]);
        setAssetsAndLiabilityType(e.target.value);
    };
    const connections = useSelector((state) => state.connection)
    useEffect(() => {
        if (props_modelHideshow) {
            if (connections.getConnections) {
                if (props_ConnectionLinkBankingAndInvestment.linkData != null) {
                    setSelectedOptions(props_ConnectionLinkBankingAndInvestment.linkData.linked_liabilities);
                    setName(props_ConnectionLinkBankingAndInvestment.linkData.name);
                    setSelectedAccount(props_ConnectionLinkBankingAndInvestment.linkData.account);
                    setOwned(props_ConnectionLinkBankingAndInvestment.linkData.owned);
                    setAvaibleCash(props_ConnectionLinkBankingAndInvestment.linkData.avalibaleCash);
                    setNotes(props_ConnectionLinkBankingAndInvestment.linkData.notes);
                    // setBalance(props_ConnectionLinkBankingAndInvestment.linkData.value);
                    setSelectedEntity(props_ConnectionLinkBankingAndInvestment.linkData.entityId);
                    setAssetsAndLiability(props_ConnectionLinkBankingAndInvestment.linkData.type);
                    setAssetsAndLiabilityType(props_ConnectionLinkBankingAndInvestment.linkData.typeName);
                    if (props_ConnectionLinkBankingAndInvestment.linkData.linkedStatus) {
                        setLinkedAndUnlinked(props_ConnectionLinkBankingAndInvestment.linkData.linkedStatus);
                    }
                }
                setLinkedLiabilites(connections.getConnections.liabilities);
                setEntity(connections.getConnections.entity);
            }
        } else {
            resetAllState();
        }
    }, [props_modelHideshow])
    const resetAllState = () => {
        setSelectedOptions([]);
        setName(null);
        setOwned(100);
        setAvaibleCash('no');
        setNotes('');
        // setBalance(0);
        setSelectedEntity('');
        setAssetsAndLiability('Assets');
        setLinkedAndUnlinked('linked')
        setSelectedAccount('');
        setAssetsAndLiabilityType();
    }
    const accountLinkStatusChanges = () => {
        Swal.fire({
            title: "Are you sure, you want to unlink?",
            icon: "question",
            confirmButtonColor: "#f32013",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let data = {
                    connectionId: props_ConnectionLinkBankingAndInvestment.connectionId,
                    accountId: props_ConnectionLinkBankingAndInvestment.accountId,
                    connectionType: props_ConnectionLinkBankingAndInvestment.connectionType
                };
                let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
                axiosClient.post("connection/linking/status" + queryParams, data).then(({ data }) => {
                    resetAllState();
                    let userId = params && params.userId &&  role == 'advisor' ? params.userId : '';
                    dispatch(getConnections(userId));
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                }).catch(error => {
                    resetAllState();
                    toast.error('There is some issue with the account unlinking.Please try again.', {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                })
            }
        });
    }
    const createLinkToAccountFunc = (saveOrUpdate) => {
        // const decimalRegex = /^\d+(\.\d{1,2})?$/;

        // if (decimalRegex.test(balance)) {
        if (selectedEntity != '' && selectAccount != '' && selectedAssetsAndLiabilityType != '' && isOwned != '') {
            let postData = {
                name: isName,
                typeName: selectedAssetsAndLiabilityType,
                type: selectedAssetsAndLiability,
                account: selectAccount,
                entityId: selectedEntity,
                owned: isOwned,
                avalibaleCash: isAvaibleCash,
                value: null,
                notes: isNotes,
                accountId: props_ConnectionLinkBankingAndInvestment.accountId,
                connectionId: props_ConnectionLinkBankingAndInvestment.connectionId,
                connectionType: props_ConnectionLinkBankingAndInvestment.connectionType,
                connectionType: props_ConnectionLinkBankingAndInvestment.connectionType,
                linkingStatus: selectedLinkedAndUnlinked,
                linkData: props_ConnectionLinkBankingAndInvestment.linkData,
                linkedLiabilites: selectedOptions,
                submitStatus: saveOrUpdate
            }
            let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
            axiosClient.post('connection/link/account'+queryParams, postData)
                .then(({ data }) => {
                    setEntitySubmit(false);
                    resetAllState();
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    let queryParams = params && params.userId &&  role == 'advisor' ? params.userId : '';
                    dispatch(getConnections(queryParams));
                    props_callModelCloseFunction();
                }).catch(error => {
                    setEntitySubmit(false);
                    resetAllState();
                    toast.error('There is some issue with the account linking.Please try again.', {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                })

        }
        // } else {
        //     toast.error('Value is invalid. Please add a number up to two decimal places.', {
        //         position: "bottom-right",
        //         autoClose: 1500,
        //         theme: "colored",
        //     });
        // }
    }
    const handleSelectChange = (selected) => {
        setSelectedOptions(selected);
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Link Account</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="entity_type" className="form-label">
                                Asset or Liability:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedAssetsAndLiability}
                                onChange={handleAssetsAndLiabilityChange}
                                required
                            >
                                <option value="Assets">Asset</option>
                                <option value="Liability">Liability</option>
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="entity_type" className="form-label">
                                {selectedAssetsAndLiability} Type:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedAssetsAndLiabilityType}
                                onChange={handleAssetsAndLiabilitypeChange}
                                required
                            >
                                <option value="">Select Type</option>
                                {
                                    selectedAssetsAndLiability == 'Assets'

                                        ?
                                        <>
                                            <option value="Cash">Cash</option>
                                            <option value="Non-Registered Investments">Non-Registered Investments</option>
                                            <option value="Registered Investments">Registered Investments</option>
                                            <option value="Real Estate">Real Estate</option>
                                            <option value="Other Assets">Other Assets</option>
                                        </>

                                        :
                                        <>
                                            <option value="Mortgages">Mortgages</option>
                                            <option value="Loans/Leases">Loans/Leases</option>
                                            <option value="Line of Credit">Line of Credit</option>
                                            <option value="Credit Card">Credit Card</option>
                                            <option value="Other Liability">Other Liability</option>
                                        </>
                                }
                            </select>
                        </div>

                        <div className="mb-3">
                            <label htmlFor="Account" className="form-label">
                                Account:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectAccount}
                                onChange={handleAccountChange}
                                required
                            >
                                <option value="">Select Account</option>
                                <option value="Reg">Reg</option>
                                <option value="Non Reg">Non Reg</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="link_to_entity" className="form-label">
                                Link to Entity:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={selectedEntity}
                                onChange={(e) => { setSelectedEntity(e.target.value) }}
                                required
                            >
                                <option value="">Select Entity</option>
                                {getEntity.map((entity, index) => (
                                    <option value={entity.id}>{entity.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className="mb-3 percent-input-container">
                            <label htmlFor="owned" className="form-label">
                                %Owned:
                            </label>
                            <input
                                type="text"
                                className={isOwned > 0 ? 'form-control positive-balance' : 'form-control'}
                                value={isOwned}
                                onChange={(e) => {
                                    const inputValue = e.target.value;
                                    // Remove non-numeric characters except the dot (.)
                                    const numericValue = parseFloat(inputValue.replace(/[^0-9.]/g, ''));
                                    // Ensure numericValue is not NaN (e.g., if the input is empty or invalid)
                                    if (!isNaN(numericValue)) {
                                        if (numericValue > 100) {
                                            setOwned(100);
                                        } else {
                                            setOwned(numericValue);
                                        }
                                    } else {
                                        setOwned('');
                                    }
                                }}
                                id="owned"
                                required
                                max="100"
                            />
                            <span className="percent-symbol mt-3">%</span>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Account" className="form-label">
                                Add to Available Cash:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={isAvaibleCash}
                                onChange={e => setAvaibleCash(e.target.value)}
                                required
                            >
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                            </select>
                        </div>
                        {
                            selectedAssetsAndLiabilityType == 'Real Estate'
                                ?
                                <div className="mb-3">
                                    <label htmlFor="Account" className="form-label">
                                        Linked Liabilities:
                                    </label>

                                    <Select options={isLinkedLiabilites} isMulti value={selectedOptions}
                                        onChange={handleSelectChange} />
                                </div>
                                :
                                null
                        }
                        <div className="mb-3">
                            <label htmlFor="notes" className="form-label">
                                Notes:
                            </label>
                            <textarea
                                id="notes"
                                className="form-control"
                                required
                                value={isNotes} onChange={e => setNotes(e.target.value)}
                            ></textarea>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        onClick={() => createLinkToAccountFunc(props_ConnectionLinkBankingAndInvestment.linkData != null
                            ?
                            'Update'
                            :
                            'Save')}
                        disabled={isEntitySubmit}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>
                                        {
                                            props_ConnectionLinkBankingAndInvestment.linkData != null
                                                ?
                                                'Update'
                                                :
                                                'Save'
                                        }
                                    </>
                                )
                        }
                    </button>
                    {
                        props_ConnectionLinkBankingAndInvestment.linkData != null
                            ?
                            <button type="submit" className="btn btn-primary"
                                onClick={accountLinkStatusChanges}
                            >
                                <>
                                    {
                                        selectedLinkedAndUnlinked != 'linked' ?
                                            null
                                            :
                                            'Unlink'
                                    }
                                </>
                            </button>
                            :
                            null
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateLinkToEntity;