import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import axios from 'axios';
import Toggle from 'react-toggle'
let count = 0;
const EditAdvisorModel = ({ props_modelHideshow, props_callModelCloseFunction, props_editAdvisor }) => {
    const [isName, setName] = useState('');
    const [isUserEmail, setUserEmail] = useState(null);
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [meetingUrl, setMeetingUrl] = useState('');
    const [designation, setDesignation] = useState('');
    const [logo, setLogo] = useState(null);
    const [urlError, setUrlError] = useState('');
    const [isS3Url, setS3Url] = useState(null);
    useEffect(() => {
        if (props_modelHideshow) {
            if (props_editAdvisor && props_editAdvisor.id) {
                fetchAdvisorUser(props_editAdvisor.id);
            }
        } else {
            setName('');
            setUserEmail(null);
            setIsValidEmail(true);
            setAddress('');
            setPhone('');
            setMeetingUrl('');
            setDesignation('');
            setLogo(null);
            setUrlError('');
            setS3Url('');
        }
    }, [props_modelHideshow])
    const fetchAdvisorUser = (id) => {
        let postData = {
            id: id
        }
        axiosClient.post('advsior-user/details', postData).then(({ data }) => {
            if (data.status == 200) {
                // return 
                setName(data.user.name);
                setUserEmail(data.user.email);
                setIsValidEmail(true);
                setAddress(data.user.address);
                setPhone(data.user.phone);
                setMeetingUrl(data.user.url);
                setDesignation(data.user.designation);
                setLogo(data.user.logo);
            }
        }).catch(error => {
            console.log('error', error);
        })
    }
    const createAdvisorFunc = () => {

        setEntitySubmit(true)
        console.log('logo', isS3Url);
        let postData = {
            id: props_editAdvisor.id,
            name: isName,
            email: isUserEmail,
            address: address,
            phone: phone,
            url: meetingUrl,
            designation: designation,
            logo: isS3Url ? isS3Url : logo
        }
        axiosClient.post('advisor-user/edit', postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    if (isS3Url) {
                        let logoObj = new FormData();
                        logoObj.append('fileName', isS3Url);
                        axiosClient.post('advisor-user/s3-url', logoObj)
                            .then(({ data }) => {
                                axios.put(data.pre_url, logo).then(
                                    result => {
                                        if (result && result.status === 200) {
                                        }
                                    }
                                )
                            }).catch(error => {
                            })
                    }
                    setEntitySubmit(false);
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    props_callModelCloseFunction();
                } else {
                    setEntitySubmit(false);
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }
    function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let randomString = '';

        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charactersLength);
            randomString += characters.charAt(randomIndex);
        }

        return randomString;
    }
    const handleEmailChange = (e) => {
        const newEmail = e.target.value;
        setUserEmail(newEmail);
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        setIsValidEmail(emailPattern.test(newEmail));
    };

    const handleLogoChange = (e) => {
        // Handle logo file upload
        const file = e.target.files[0];
        const fileName = file['name'].slice(((file['name'].lastIndexOf(".") - 1) >>> 0) + 2);
        const s3FileName = generateRandomString(100) + '.' + fileName;
        setS3Url(s3FileName);
        setLogo(file);
    };
    const validateUrl = (url) => {
        try {
            new URL(url);
            return '';
        } catch (error) {
            return "Please enter a valid URL.";
        }
    }
    const handleUrlChange = (e) => {
        const newUrl = e.target.value;
        setMeetingUrl(newUrl);
        const error = validateUrl(newUrl);
        setUrlError(error);
    };
    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Advisor</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        {/* Advisor Name */}
                        <div className="mb-3">
                            {/* field Name label*/}
                            <label htmlFor="name" className="form-label">
                                Name*:
                            </label>
                            {/* Advisor Name input */}
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Email" className="form-label">
                                Email*:
                            </label>
                            <input
                                type="email"
                                value={isUserEmail}
                                onChange={handleEmailChange}
                                className={`form-control ${isValidEmail ? '' : 'is-invalid'}`}
                                id="exampleInputPassword1"
                            />
                            {isValidEmail ? null : <div className="invalid-feedback">Invalid email address</div>}
                        </div>  <div className="mb-3">
                            <label htmlFor="address" className="form-label">
                                Address:
                            </label>
                            <input
                                type="text"
                                value={address}
                                onChange={(e) => setAddress(e.target.value)}
                                className="form-control"
                                id="address"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="phone" className="form-label">
                                Phone:
                            </label>
                            <input
                                type="tel"
                                value={phone}
                                onChange={(e) => {
                                    const x = e.target.value
                                        .replace(/\D/g, '')
                                        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
                                    let number = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`
                                    setPhone(number)
                                }}
                                className="form-control"
                                id="phone"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="meetingUrl" className="form-label">
                                Meeting URL (e.g. Calendly):
                            </label>
                            <input
                                type="url"
                                value={meetingUrl}
                                onChange={handleUrlChange}
                                className={`form-control ${urlError == '' ? '' : 'is-invalid'}`}
                                id="meetingUrl"
                            />
                        </div>
                        {urlError && <div className="error-message">{urlError}</div>}
                        <div className="mb-3">
                            <label htmlFor="designation" className="form-label">
                                Designation:
                            </label>
                            <input
                                type="text"
                                value={designation}
                                onChange={(e) => setDesignation(e.target.value)}
                                className="form-control"
                                id="designation"
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="logo" className="form-label">
                                Logo:
                            </label>
                            <input
                                type="file"
                                accept="image/*"
                                onChange={handleLogoChange}
                                className="form-control"
                                id="logo"
                            />
                        </div>
                        {
                            logo != null

                                ?
                                <>
                                    {
                                        isS3Url == ''
                                            ?
                                            <div>
                                                <p>Selected Logo:</p>
                                                <img src={'https://nwd-main-01.s3.ca-central-1.amazonaws.com/' + logo} height={100} width={100} alt="Selected Logo" />
                                            </div>
                                            :
                                            <div>
                                                <p>Selected Logo:</p>
                                                <img src={URL.createObjectURL(logo)} height={100} width={100} alt="Selected Logo" />
                                            </div>

                                    }
                                </>


                                :
                                null
                        }
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={isValidEmail && isName.length > 0 ? false : true}
                        onClick={createAdvisorFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Update</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default EditAdvisorModel;