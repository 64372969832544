// 
import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import connection, {
    removeConnectionDetails
} from '../../store/apps/connection';
import axiosClient from "../../axios-client";
import { useDispatch, useSelector } from 'react-redux'
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
const TroubleshootModel = ({ props_modelHideshow, props_callModelCloseFunction, props_ConnectionId }) => {
     // url params 
     const params = useParams();
     const { role } = useStateContext();
    const [isLoadingRequest, setLoadingRequest] = useState(false);
    const [isAccountData, setAccountData] = useState(null);
    const [isRefreshLoading, setRefreshLoading] = useState(false);
    const [isFlinksDetails, setFlinksDetails] = useState(null);
    const dispatch = useDispatch();
    const connections = useSelector((state) => state.connection);
    useEffect(() => {

    }, [connections])
    useEffect(() => {
        if (props_modelHideshow) {
            setFlinksDetails(null);
            fetchNightRefreshTroubleShootFlinks()
        } else {
            setRefreshLoading(false);
            setLoadingRequest(false);
            setFlinksDetails(null);
        }
    }, [props_modelHideshow])

    const fetchNightRefreshTroubleShootFlinks = () => {
        setLoadingRequest(true)
        let postData = {
            connectionId: props_ConnectionId
        }
        let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('connection/night-refresh-date-time/data'+queryParams, postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    setLoadingRequest(false)
                    setFlinksDetails(data.flinksDetails);
                } else {
                    setLoadingRequest(false)
                }
            }).catch(error => {
                setLoadingRequest(false)
                console.log('error occurd', error);
            })
    }
    const refreshData = () => {
        setRefreshLoading(true);
        let postData = {
            connectionId: props_ConnectionId
        }
        let queryParams = params && params.userId &&  role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('connection/refresh-today/data'+queryParams, postData)
            .then(({ data }) => {
                if (data.status == 200) {
                    toast.success(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                    setRefreshLoading(false);
                    props_callModelCloseFunction();
                } else {
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                toast.error(error, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setLoadingRequest(false)
                console.log('error occurd', error);
            })
    }


    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Troubleshoot Flinks</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <>
                        {
                            isFlinksDetails != null && isFlinksDetails.userName
                                ?
                                <>
                                    <div>Institution Name : <span className="trouble-shoot-custom">{' ' + isFlinksDetails.institute}</span></div>
                                    <div>Username : <span className="trouble-shoot-custom">{' ' + isFlinksDetails.userName}</span></div>
                                    <div>Nightly refresh status : <span className="trouble-shoot-custom">{' ' + isFlinksDetails.status}</span></div>
                                    <div>Last Nightly Refresh Date/Time :<span className="trouble-shoot-custom">{' ' + isFlinksDetails.lastRefresh}</span></div>
                                    <br />
                                    <div className="go-to-login create_entity_fill"
                                        onClick={() => isRefreshLoading ? null : refreshData()}
                                    >
                                        <a href="#" >
                                            {
                                                isRefreshLoading
                                                    ?
                                                    <>
                                                        <div className="spinner-border m-1" role="status"></div>
                                                        <span>Please wait...</span>
                                                    </>
                                                    :
                                                    <>Refresh Today's Data</>
                                            }
                                        </a>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="card loadingCard loadingCardOverlap inlineLoading">
                                        <div className="card-body">
                                            <div className="componentLoading bg-white text-center">

                                                {
                                                    isLoadingRequest
                                                        ?
                                                        <div className="spinner-border m-1" role="status"></div>
                                                        :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </>
                        }
                    </>
                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default TroubleshootModel;