import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import connection, {
    getAdvisorsData
} from '../store/apps/connection';
import { useNavigate } from 'react-router-dom'
import { useStateContext } from "../Context/ContextProvider";
import _ from 'lodash';
import CreateAdvisorModel from '../Components/Modals/CreateAdvisorModel';
import EditAdvisorModel from "../Components/Modals/EditAdvisorModel";
import { Dropdown } from 'react-bootstrap';
import Toggle from 'react-toggle';
import Swal from "sweetalert2";
import axiosClient from "../axios-client";
import { toast } from "react-toastify";
let count = 0;
const Advisor = () => {
    // redux dispatch fucntion
    const dispatch = useDispatch();
    // navigation function
    const navigate = useNavigate();
    // get permission from context provider
    const { permissions } = useStateContext();
    // Create User Model hide show
    const [isShowCreateAdvisorModel, setShowCreateAdvisorModel] = useState(false);
    const [isShowEditAdvisorModel, setShowEditAdvisorModel] = useState(false);
    const [isShowSetPasswordAdminModel, setShowSetPasswordAdvisorModel] = useState(false);
    const [isEditProps, setEditProps] = useState({});
    // admin table data state
    const [isAdvisorData, setAdvisorData] = useState(null);
    // table loading state
    const [isLoading, setIsLoading] = useState(false);
    // redux fetch data state
    const connections = useSelector((state) => state.connection)
    // useEffect calling getadmidata fucntion in redux file
    useEffect(() => {
        setIsLoading(true)
        dispatch(getAdvisorsData());
    }, [dispatch])
    // useEffect get user/admins api response in this..
    useEffect(() => {
        if (connections.getAdvisorsData && connections.getAdvisorsData.status == 200) {
            setAdvisorData(connections.getAdvisorsData.admins);
            count = count +1;
        }
    }, [connections])
    useEffect(() => {
        setTimeout(() => {
            if (permissions != undefined) {
                if (_.includes(permissions, 'view_advisors')) {
                } else {
                    navigate('/page-404');
                }
            }
        }, 1000);

    }, [permissions])
    const addAdvisorUser = () => {
        setShowCreateAdvisorModel(true);
    }
    const handleShowEntityModalClose = () => {
        setShowCreateAdvisorModel(false);
        dispatch(getAdvisorsData());
    }
    const moveToAdvisorDashboard = (id) => {
        axiosClient.get('impersonate/advisor/' + id)
            .then(async ({ data }) => {
                console.log('data', data);
                if (data.status == 200) {
                    let token = await localStorage.getItem('ACCESS_TOKEN');
                    await localStorage.setItem('PREVIOUS_TOKEN', token);
                    localStorage.setItem('ACCESS_TOKEN', data.token)
                    navigate('/');
                    window.location.reload();
                } else {
                    toast.error(data.message, {
                        position: "bottom-right",
                        autoClose: 1500,
                        theme: "colored",
                    });
                }
            }).catch(error => {
                console.log('error occurd', error);
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
            })
    }
    const editUserFunc = (user) => {
        // console.log('user',user);
        setEditProps(user);
        setShowEditAdvisorModel(true);
    }
    const setPasswordFunc = (user) => {
        setEditProps(user);
        setShowSetPasswordAdvisorModel(true);
    }
    const handleShowEditAdvsiorUserModalClose = () => {
        setShowEditAdvisorModel(false);
        dispatch(getAdvisorsData());
    }
    const handleShowSetPasswordAdminUserModalClose = () => {
        setShowSetPasswordAdvisorModel(false);
        dispatch(getAdvisorsData());
    }
    const changeAdminAccountStatus = (status, id) => {
        let title = status ? "Are you sure want to Enable?" : "Are you sure want to Disable?";
        Swal.fire({
            title: title,
            icon: "question",
            confirmButtonColor: "#f32013",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            showCancelButton: true,
        }).then((result) => {
            if (result.isConfirmed) {
                let payload = {
                    id: id,
                    userStatus: status
                };
                axiosClient.post("advisor-change/status", payload).then((response) => {
                    if (response.data.status == 200) {
                        dispatch(getAdvisorsData());
                        toast.success(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    } else {
                        dispatch(getAdvisorsData());
                        toast.error(response.data.message, {
                            position: "bottom-right",
                            autoClose: 1500,
                            theme: "colored",
                        });
                    }
                });
            } else {
                dispatch(getAdvisorsData());
            }
        });
    }
    return (
        <>
            <div className="main_client">
                {
                    permissions != undefined && _.includes(permissions, 'manage_admins')
                        ?
                        <div className="main_client_btn" onClick={() => addAdvisorUser()}>
                            <button>
                                Create Advisor
                            </button>
                        </div>
                        :
                        null
                }
                <div className="main_client_header">
                    <div>
                        <div className="input-group mb-3">
                            <input type="text" className="form-control" placeholder="Search name" id="form-control" />
                            <span className="input-group-text" id="basic-addon1"><i className="fa fa-search"></i></span>

                        </div>
                    </div>
                    <div>
                        {/* <input type="date" className="form-control form-input" /> */}
                    </div>
                    <div className="d-flex align-items-center">
                        {/* <span className="me-2" >to</span> <input type="date"
                            className="form-control form-input" /> */}
                    </div>
                    <div>
                        {/* <select className="form-select form-input form-select" aria-label="Default select example">
                            <option value="1">Sort</option>
                            <option value="2">Two</option>

                        </select> */}
                    </div>
                    <div>
                        <div className="form-check">
                            {/* <input className="form-check-input form-radio" type="checkbox" name="flexRadioDefault"
                                id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Show inactive
                            </label> */}
                        </div>
                    </div>
                </div>
                {
                    isAdvisorData != null && isAdvisorData.length > 0
                        ?
                        <>
                            {isAdvisorData.map((user, index) => (
                                <div className="main_client_main" key={index}>
                                    <div className="client_main_wrapper">
                                        <div className="client_wrapper_text">
                                            <h1>
                                                {user.name}
                                            </h1>
                                            <p>
                                                {user.email}
                                            </p>
                                        </div>
                                        <div className="client_wrapper_amount amount_1">
                                            <h1>
                                                total Clients
                                            </h1>
                                            <p>
                                                {user.users}
                                            </p>
                                        </div>
                                        <div className="client_wrapper_amount amount_1">
                                            <h1>
                                                Status
                                            </h1>
                                            <p className="mt-2" key={count}>
                                                <Toggle
                                                    defaultChecked={user.userStatus}
                                                    icons={false}
                                                    size={10}
                                                    onChange={(e) => { changeAdminAccountStatus(!user.userStatus, user.id) }}
                                                />
                                            </p>
                                        </div>

                                        <div className="client_wrapper_btn client_wrapper_btn_1">
                                            <button onClick={() => moveToAdvisorDashboard(user.id)}>
                                                Impersonate
                                            </button>
                                        </div>
                                        <div>
                                            <Dropdown className="custom-dropdown" >
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    <i className="fa fa-ellipsis-vertical" ></i>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item href="#"
                                                        onClick={() => {
                                                            editUserFunc(user);
                                                        }}>Edit</Dropdown.Item>
                                                    <Dropdown.Item href="#" onClick={() => {
                                                        setPasswordFunc(user);
                                                    }}>Set Password</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                            {/* <i class="fa-solid fa-ellipsis-vertical fs-5" ></i> */}
                                        </div>
                                    </div>


                                </div>
                            ))}
                        </>
                        :
                        null

                }

                <div className="main_client_footer mt-3">
                    <p>
                        total Advisors : <span>{isAdvisorData != null && isAdvisorData.length > 0 ? isAdvisorData.length : 0}</span>
                    </p>
                    {/* <div className="ms-auto mt-3">
                        <nav aria-label="Page navigation example ">
                            <ul className="pagination">
                                <li className="page-item link-frist"><a className="page-link  " href="#"><i
                                    className="fa-solid fa-caret-left"></i></a></li>
                                <li className="page-item"><a className="page-link active" href="#">1</a></li>
                                <li className="page-item"><a className="page-link" href="#">2</a></li>
                                <li className="page-item"><a className="page-link" href="#">3</a></li>
                                <li className="page-item"><a className="page-link" href="#">....</a></li>
                                <li className="page-item"><a className="page-link" href="#">7</a></li>
                                <li className="page-item"><a className="page-link" href="#">8</a></li>
                                <li className="page-item link-frist"><a className="page-link active" href="#"><i
                                    className="fa-solid fa-caret-right "></i></a></li>
                            </ul>
                        </nav>
                    </div> */}
                </div>
                {/* <div className="client_footer">
                    <select className="form-select  form-select-2" aria-label="Default select example">
                        <option value="1">Admin Per Page 7:</option>
                        <option value="2">Two</option>

                    </select>
                </div> */}
            </div>
            <CreateAdvisorModel props_modelHideshow={isShowCreateAdvisorModel} props_callModelCloseFunction={handleShowEntityModalClose} />
            <EditAdvisorModel props_modelHideshow={isShowEditAdvisorModel} props_callModelCloseFunction={handleShowEditAdvsiorUserModalClose} props_editAdvisor={isEditProps} />

        </>
    )
}

export default Advisor;