import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import axiosClient from "../../axios-client";
import { toast } from "react-toastify";
import { useParams } from 'react-router-dom';
import { useStateContext } from "../../Context/ContextProvider";
const CreateEnitityModel = ({ props_modelHideshow, props_callModelCloseFunction }) => {
    const { permissions, role } = useStateContext();
    // url params 
    const params = useParams();
    const [isName, setName] = useState('');
    const [isEntitySubmit, setEntitySubmit] = useState(false);
    const [isEntityType, setEntityType] = useState('Personal');
    useEffect(() => {
        if (props_modelHideshow) {
        } else {
            setEntityType('Personal');
            setName('');
        }
    }, [props_modelHideshow])
    const createAnEntityFunc = () => {
        setEntitySubmit(true)
        let postData = {
            name: isName,
            type: isEntityType
        }
        let queryParams = params && params.userId && role == 'advisor' ? '?userId=' + params.userId : '';
        axiosClient.post('entity/save' + queryParams, postData)
            .then(({ data }) => {
                setEntityType('Personal');
                setName('');
                setEntitySubmit(false);
                toast.success(data.message, {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                props_callModelCloseFunction();
            }).catch(error => {
                console.log('error occurd')
                toast.success('There is some error while adding entity.Try again.', {
                    position: "bottom-right",
                    autoClose: 1500,
                    theme: "colored",
                });
                setEntitySubmit(false);
            })
    }

    return (
        <>
            <Modal
                backdrop="static"
                centered
                show={props_modelHideshow}
                onHide={props_callModelCloseFunction}
                className="hide-btn-close"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Create an Entity</Modal.Title>
                    <button
                        type="button"
                        className="btnClose custom"
                        onClick={props_callModelCloseFunction}
                    >
                        <i className="fa fa-close"></i>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={e => e.preventDefault()}>
                        <div className="mb-3">
                            <label htmlFor="Account" className="form-label">
                                Entity Type:
                            </label>
                            <select
                                className="form-select"
                                id="type"
                                value={isEntityType}
                                onChange={e => setEntityType(e.target.value)}
                                required
                            >
                                <option value="Personal">Personal</option>
                                <option value="Business">Business</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                                Entity Name:
                            </label>
                            <input type="name"
                                value={isName} onChange={e => setName(e.target.value)}
                                className="form-control" id="name" required
                            />
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="btn btn-outline-primary"
                        onClick={props_callModelCloseFunction}
                    >
                        Close
                    </Button>
                    <button type="submit" className="btn btn-primary"
                        disabled={isName.length > 0 ? false : true}
                        onClick={createAnEntityFunc}
                    >
                        {
                            isEntitySubmit ?
                                (
                                    <>Please Wait <span className="spinner-grow spinner-grow-sm ml-2" role="status" aria-hidden="true"></span></>
                                ) : (
                                    <>Save</>
                                )
                        }

                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CreateEnitityModel;