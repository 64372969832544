import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axiosClient from "../axios-client";

const Signup = () => {

   

    return (
        <></>
    );
};

export default Signup;