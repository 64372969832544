import React, { useEffect, useState } from "react";
import axiosClient from '../axios-client';
import { useStateContext } from "../Context/ContextProvider";
import { useNavigate } from 'react-router-dom'
const PageNotFound = () => {
  const navigate = useNavigate();
  const { setUser, setToken } = useStateContext();
  const onLogout = (e) => {
    e.preventDefault()

    axiosClient.post('/logout')
        .then(() => {
            setUser({})
            setToken(null)
            navigate("/login")
        })
}
  return (
    <>
      <div className="page-not-found">
        <div className="container">
          <h1>404</h1>
          <p>Page not found</p>
          <div className="go-to-login create_entity_fill" onClick={onLogout}>
            <a href="#" >Go to Login</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageNotFound